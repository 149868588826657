<template>
  <v-toolbar
    app
    class="toolbar"
    flat
  >
    <v-toolbar-side-icon
      class="hidden-md-and-up"
      @click="toggleDrawer"
    />
    <v-container
      mx-auto
      py-0
    >
      <v-layout>
        <v-img
          :src="require('@/assets/logo.png')"
          class="mr-5"
          contain
          height="48"
          width="48"
          max-width="48"
          style="cursor: pointer;"
          @click="goToElement({to: '#welcome', text: ('guest.home')})"
        />
        <router-link
          v-for="(link, i) in links"
          :key="i"
          :to="link"
          class="ml-0 hidden-sm-and-down"
          active-class="primary--text"
        >
          <v-btn
            flat
          >
            {{ $t(link.text) }}
          </v-btn>
        </router-link>
        <v-spacer/>
        <v-btn
          :to="{ name: 'SignIn' }"
          color="primary"
        >
          {{$t('auth.login')}}
        </v-btn>
      </v-layout>
    </v-container>
  </v-toolbar>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Getter,
  } from 'vuex-class';

  @Component
  export default class GuestToolbar extends Vue {
    @Getter('guest/auth/isAdmin') private isAdmin!: any;

    private links: any = [
      {name: 'GuestHome', hash: '#welcome', text: ('guest.home')},
      {name: 'GuestAnimals', text: ('guest.animals')},
      {name: 'GuestHome', hash: '#about', text: ('guest.eAdoption')},
      {name: 'GuestHome', hash: '#impress', text: ('guest.contact')},
    ];

    private toggleDrawer() {
      this.sidebar = !this.sidebar;
    }

    private goToElement(element) {
      if (element.route) {
        this.$router.push({name: element.route});
      } else if (this.$router.currentRoute.name === 'GuestHome' && element.hash) {
        this.$vuetify.goTo(element.hash);
      } else {
        this.$router.push({name: 'GuestHome', hash: element.hash});
      }
    }

    private get sidebar() {
      return this.$store.getters['common/ui/sidebar'];
    }

    private set sidebar(value) {
      this.$store.dispatch('common/ui/setSidebar', value);
    }
  }
</script>

<style scoped lang="scss">
  .theme--light.v-toolbar {
    &.toolbar {
      backdrop-filter: blur(4px);
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgb(241, 241, 241);
    }
  }
</style>
