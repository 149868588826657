<template>
  <div class="fill-height">
    <user-toolbar/>
    <user-navigation/>
    <v-content class="fill-height">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-content>
    <v-dialog v-model="showGivenNameModal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Vyberte si svoje používateľské meno</v-card-title>
        <v-card-text>
          <p class="body-1">
            Prosím, zadajte meno, pod ktorým budete písať komentáre. Tento názov bude viditeľný pre ostatných používateľov. Vyberte meno, ktoré vás bude reprezentovať a je vhodné pre verejnú diskusiu.
          </p>
          <v-text-field
            v-model="preferred_usernameField"
            label="Používateľské meno"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateName" :loading="loading">Uložiť</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action, Getter,
  } from 'vuex-class';
  import UserNavigation from '@/components/User/UserNavigation.vue';
  import UserToolbar from '@/components/User/UserToolbar.vue';

  @Component({
    components: {
      UserToolbar,
      UserNavigation,
    },
  })
  export default class UserLayout extends Vue {
    @Action('guest/auth/signOut') private storeSignOut!: () => Promise<void>;
    @Action('guest/auth/loadUser') private loadUser!: () => Promise<void>;

    @Action('guest/auth/updatePreferred_Username') private updatePreferred_Username!: (preferred_username: string) => Promise<void>;

    @Getter('guest/auth/preferred_username') private preferred_username!: string;

    public showGivenNameModal: boolean = false;
    public preferred_usernameField: string = '';
    public loading: boolean = false;

    private signOut(): void {
      this.storeSignOut().then(() => {
        this.$router.push({name: 'GuestHome'});
      });
    }

    private async created() {
      await this.loadUser();
      if (!this.preferred_username) {
        this.showGivenNameModal = true;
      }
    }

    public async updateName() {
      this.loading = true;
      await this.updatePreferred_Username(this.preferred_usernameField);
      this.loading = false;
      this.showGivenNameModal = false;
    }
  }
</script>

<style >
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
}
</style>

<style lang="scss">
  @import '../../styles/front.scss';
</style>
