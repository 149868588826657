import AnimalPost from '@/views/User/Animals/Post.vue';
import {Auth} from 'aws-amplify';

import UserBase from '../views/User/Layout.vue';
import Feed from '../views/User/Feed.vue';
import Animals from '../views/User/Animals/List.vue';
import Animal from '../views/User/Animals/Details.vue';
import Settings from '../views/User/Settings.vue';
import PaymentSuccess from '../views/User/Payment/Success.vue';
import PaymentError from '../views/User/Payment/Error.vue';

function mustBeUser(to: any, from: any, next: any) {
  Auth.currentAuthenticatedUser().then((user) => {
    if (user.signInUserSession.idToken.payload['cognito:groups']) {
      const role = user.signInUserSession.idToken.payload['cognito:groups'][0];
      if (role === 'Admin') {
        return next({name: 'AdminBase'});
      } else if (role === 'Moderator') {
        return next({name: 'ModeratorBase'});
      }
    }
    return next();
  }).catch(() => {
    return next({name: 'GuestBase'});
  });
}

export default [
  {
    path: '/user',
    name: 'UserBase',
    component: UserBase,
    beforeEnter: mustBeUser,
    redirect: {name: 'UserFeed'},
    children: [
      {
        path: 'feed',
        name: 'UserFeed',
        component: Feed,
      },
      {
        path: 'animals',
        name: 'UserAnimals',
        component: Animals,
      },
      {
        path: 'animals/:id',
        name: 'UserAnimal',
        component: Animal,
        props: true,
      },
      {
        path: 'animals/:id/post/:postId',
        name: 'UserAnimalPost',
        component: AnimalPost,
        props: true,
      },
      {
        path: 'settings',
        name: 'UserSettings',
        component: Settings,
      },
      {
        path: 'payment/success',
        name: 'PaymentSuccess',
        component: PaymentSuccess,
      },
      {
        path: 'payment/error',
        name: 'PaymentError',
        component: PaymentError,
      },
    ],
  },
];
