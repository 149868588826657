// @ts-ignore
export const all = (state) => {
  return state.all.map((el) => ({ ...el, end_date: getEndDate(el.entry_date) }));
};

function getEndDate(val) {
  const date = new Date(val);

  return `${date.getDate()}.${date.getUTCMonth() + 2}.${date.getUTCFullYear()}`;
}
