import IModerator from '../_types/moderator';

// @ts-ignore
export const all = (state) => {
  const users: IModerator[] = [];

  for (const key in state.all) {
    if (!state.all.hasOwnProperty(key)) {
      continue;
    }
    const user = {
      username: state.all[key].Username,
      email: state.all[key].Attributes.filter((el) => el.Name === 'email')[0].Value,
      status: state.all[key].UserStatus,
    };
    users.push(user);
  }
  return users;
};
