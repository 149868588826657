<template>
  <v-img
    v-if="src"
    :src="src"
    :lazy-src="lazySrc"
    @error="error"
    @load="load"
    :class="{ border: border }"
    :contain="contain"
  >
    <template v-slot:placeholder>
      <v-layout
        fill-height
        align-center
        justify-center
        ma-0
      >
        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
      </v-layout>
    </template>
  </v-img>
</template>

<script lang="ts">
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import { Storage } from 'aws-amplify';

  @Component
  export default class S3Image extends Vue {
    public src: any = '';
    public lazySrc: any = '';

    @Prop({type: String, default: ''}) private itemKey!: string;
    @Prop({type: String, default: ''}) private itemKeyLazy!: string;
    @Prop({type: String, default: ''}) private level!: 'public' | 'protected' | 'private' | undefined;
    @Prop({type: Boolean, default: false}) public border!: boolean;
    @Prop({type: Boolean, default: false}) public contain!: boolean;

    private created() {
      this.init(this.itemKey);
    }

    private async init(itemKey: string) {
      if (this.itemKeyLazy) {
        this.lazySrc = await Storage.get(itemKey, { level: this.level ? this.level : 'public' });
      }
      this.src = await Storage.get(itemKey, { level: this.level ? this.level : 'public' });
    }


    public error() {
      this.src = 'img/loader.gif';

      setTimeout(() => { this.init(this.itemKey); }, 5000);
    }

    @Watch('itemKey')
    private reloadItem() {
      this.init(this.itemKey);
    }

    public load() {
      // console.log('load');
    }
  }
</script>

<style scoped>
  .border {
    border-top: 1px solid rgb(239, 239, 239);
    border-bottom: 1px solid rgb(239, 239, 239);
  }
</style>
