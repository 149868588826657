<template>
  <div>
    <guest-hero></guest-hero>

    <section id="animals" class="py-5 mx-2" v-if="animals.length > 0">
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-center ">
          <h2 class="display-1 font-weight-light mb-4 text-uppercase">Naši miláčikovia Ťa potrebujú!</h2>
        </v-flex>
        <v-flex xs12>
          <div class="glide">
            <div class="glide__track" data-glide-el="track">
              <ul class="glide__slides">
                <li class="glide__slide py-3 h-full" v-for="animal in animals" :key="animal.id"
                    @click="$router.push({name: 'GuestAnimal', params: {id: animal.id}})"
                    style="cursor: pointer">
                  <animal-card :animal="animal"></animal-card>
                </li>
              </ul>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </section>

    <guest-talk></guest-talk>

    <guest-screenshots></guest-screenshots>
    <guest-about></guest-about>
    <guest-info></guest-info>
  </div>
</template>

<script lang="ts">
  import AnimalCard from '@/components/Global/AnimalCard.vue';
  import Animal from '@/types/Animal';
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  import GuestToolbar from '@/components/Guest/GuestToolbar.vue';
  import GuestHero from '@/components/Guest/GuestHero.vue';
  import GuestTalk from '@/components/Guest/GuestTalk.vue';
  import GuestInfo from '@/components/Guest/GuestInfo.vue';
  import GuestScreenshots from '@/components/Guest/GuestScreenshots.vue';
  import GuestScreenshots2 from '@/components/Guest/GuestScreenshots2.vue';
  import S3Image from '@/components/S3Image.vue';
  import GuestAbout from '@/components/Guest/GuestAbout.vue';
  import Glide from '@glidejs/glide';

  @Component({
    components: {
      AnimalCard,
      GuestAbout,
      S3Image,
      GuestScreenshots2,
      GuestScreenshots,
      GuestInfo,
      GuestTalk,
      GuestHero,
      GuestToolbar,
    },
  })
  export default class Layout extends Vue {
    @Action('guest/animals/read') private readAnimals!: () => Promise<void>;
    @Getter('guest/animals/all') public animals!: Animal[];

    private async mounted() {
      await this.readAnimals();

      if (this.animals.length > 0) {
        new Glide('.glide', {
          type: 'carousel',
          startAt: 0,
          perView: 6,
          autoplay: 4000,
          animationDuration: 1500,
          breakpoints: {
            600: {
              perView: 1,
            },
            1264: {
              perView: 2,
            },
            1904: {
              perView: 3,
            },
          },
        }).mount();

        if (this.$route.hash) {
          this.$vuetify.goTo(this.$route.hash);
        }
      }
    }
  }
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s ease;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0;
  }

  .v-parallax__content {
    background-color: rgba(20, 20, 50, 0.2);
  }
</style>

<style lang="scss" scoped>
  // Required Core Stylesheet
  @import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
  // Optional Theme Stylesheet
  @import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";
</style>
