import { render, staticRenderFns } from "./PostLikeField.vue?vue&type=template&id=b23b2b88&scoped=true"
import script from "./PostLikeField.vue?vue&type=script&lang=ts"
export * from "./PostLikeField.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b23b2b88",
  null
  
)

export default component.exports