<template>
  <section id="about" class="white py-5">
    <v-card class="elevation-0" >
      <v-container>
        <v-layout
          column
          wrap

          align-center
        >
          <v-flex xs12 sm4 >
            <div class="text-xs-center">
              <h2 class="display-1 v-heading mb-3 font-weight-light text-uppercase">Miláčik na diaľku</h2>
              <span class="subheading">
                Takto môžeš pomôcť
              </span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl >
              <v-layout row wrap align-top>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <v-icon x-large class="primary--text text--lighten-3">pets</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-xs-center">PODPOR SVOJHO MILÁČIKA</div>
                    </v-card-title>
                    <v-card-text class="body-1">
                      Adoptuj si miláčika na diaľku.<br/>
                      Daruj svojím PRAVIDELNÝM MESAČNÝM príspevkom lepší život zvieratku, ktoré musí v našom útulku
                      zostať dlhšie. Zatiaľ nemá žiadnu rodinu, len TEBA.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <v-icon x-large class="primary--text text--lighten-3">mobile_friendly</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline">JEDNODUCHO CEZ ONLINE</div>
                    </v-card-title>
                    <v-card-text class="body-1">
                      Použi našu aplikáciu a budeš celý čas informovaný ako sa Tvojmu miláčikovi darí a ako mu Tvoja
                      pomoc pomáha, napríklad pri očkovaní, návšteve zverolekára alebo jednoducho pri mlsaní dobrotiek.
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <v-icon x-large class="primary--text text--lighten-3">euro_symbol</v-icon>
                    </v-card-text>
                    <v-card-title primary-title class="layout justify-center">
                      <div class="headline text-xs-center display-1">ZAČNI UŽ OD 5 EUR NA MESIAC</div>
                    </v-card-title>
                    <v-card-text class="body-1">
                      Každý deň jedna dobrotka, záruka zdravotnej starostlivosti a pod… S adopciou na diaľku v hodnote
                      5€, 10€ alebo 20€/mesiac, pomôžeš svojmu miláčikovi a iným zvieratkám v útulku zlepšiť ich život.
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'GuestTalk',
  };
</script>

<style scoped>

</style>
