<template>
  <div>
    <v-snackbar
      v-for="(snackbar, i) in snackbars"
      v-model="snackbar.active"
      :key="i"
      :timeout="timeout"
      :top="true"
      :right="true"
      :color="snackbar.color"
      multi-line
    >
      <span v-html="snackbar.message"></span>
      <v-btn
        dark
        flat
        @click.native="snackbar.active = false"
      >{{$t('close')}}</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component
export default class Snackbar extends Vue {
  @Getter('common/snackbar/snackbars') private snackbars!: any[];
  private timeout: number = 5000;

  // TODO: do NOT modify getter variables, instead do some back and forth with the store to keep this clean
  // TODO: for now, cleanup is disabled as this might have weird consequences
  // @Watch('snackbars')
  // private watcher(snackbars: any) {
  //   for(const i in snackbars) {
  //     if(snackbars.hasOwnProperty(i) && !snackbars[i].active) {
  //         snackbars = snackbars.splice(i, 1);
  //     }
  //   }
  // }
}
</script>

<style scoped>

</style>
