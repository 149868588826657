<template>
  <v-container>
    <v-layout row wrap>
      <v-flex xs12 class="text-xs-center">
        <loader v-if="loading"></loader>
      </v-flex>
      <v-flex>
        <v-layout row wrap>
          <v-flex
            v-if="animals"
            v-for="(animal) in animals"
            :key="animal.id"
            xs12 sm6 md4
            class="pa-2 d-flex"
            style="cursor: pointer"
          >
            <router-link :to="{ name: 'AdminAnimal', params: { id: animal.id }}" style="display: flex; flex-grow: 1"
              class="no-link">
              <animal-card :animal="animal">
                <div class="px-3 pb-3" style="display: flex; justify-content: center; align-items: center">
                  <span class="subheading mr-2"><v-icon
                    class="mr-1 text-primary">group</v-icon>{{ animal.subscriptionCount }}</span>
                  <dlg-animals-delete :animal="animal" v-show="!animal.deleted" />
                </div>
              </animal-card>
            </router-link>
          </v-flex>
          <v-flex xs12 v-if="loading" class="text-xs-center">
            <Loader></Loader>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <dlg-animals-new />
  </v-container>
</template>

<script lang="ts">
import AnimalCard from '@/components/Global/AnimalCard.vue';
import Loader from '@/components/Loader.vue';
import S3Image from '@/components/S3Image.vue';
import DlgAnimalsDelete from '@/modules/admin/animals/_components/DlgAnimalsDelete.vue';
import DlgAnimalsEdit from '@/modules/admin/animals/_components/DlgAnimalsEdit.vue';
import DlgAnimalsNew from '@/modules/admin/animals/_components/DlgAnimalsNew.vue';
import AnimalSubscribe from '@/modules/user/subscriptions/_components/AnimalSubscribe.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    AnimalSubscribe, AnimalCard,
    Loader,
    DlgAnimalsDelete,
    S3Image,
    DlgAnimalsEdit,
    DlgAnimalsNew,
  },
})
export default class AnimalsList extends Vue {
  public loading: boolean = false;
  @Action('admin/animals/read') private readAnimals!: () => Promise<boolean>;
  @Getter('admin/animals/all') public animals!: any[];

  private mounted() {
    this.loading = true;

    this.readAnimals()
      .then((success) => {
        if (success) {
          this.loading = false;
        } else {
          throw new Error('Some Error, handle it');
        }
      })
      .catch((err) => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>
</style>
