<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-btn
      slot="activator"
      icon
      class="primary--text"
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <v-card v-if="localSubscription">
      <v-card-title class="primary">
        <span class="headline white--text">Edit Subscription</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs6>
                <v-switch
                  v-model="localSubscription.deleted"
                  :label="`Deleted: ${localSubscription.deleted.toString()}`"
                ></v-switch>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="localSubscription.entry_date"
                      label="Date"
                      hint="Entry Date"
                      persistent-hint
                      prepend-icon="event"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="localSubscription.entry_date" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="localSubscription.var_sym"
                  label="Var Sym"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="localSubscription.status"
                  label="Status"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="animals"
                  item-text="title"
                  item-value="id"
                  v-model="localSubscription.animalId"
                  label="Animal"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <small>*Required</small>
      </v-card-text>
      <v-card-actions class="mb-3">
        <v-spacer/>
        <v-btn
          class="mb-3"
          @click.native="update()"
          :loading="loading"
          :disabled="!valid"
          color="primary"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  @Component
  export default class DlgSubscriptionsEdit extends Vue {
    public $refs!: {
      form: any,
    };

    @Prop({type: Object, default: null}) private subscription!: any;
    @Action('admin/subscriptions/update') private updateSubscription!: any;
    @Action('admin/subscriptions/read') private readSubscriptions!: any;
    @Getter('common/rules/all') private rules!: any;
    @Getter('admin/animals/all') private animals!: any;

    private localSubscription: any = null;
    private dialog: boolean = false;
    private loading: boolean = false;
    private valid: boolean = true;

    private menu1: boolean = false;

    @Watch('dialog')
    private onDialogChanged(val: boolean) {
      if (val) {
        this.localSubscription = JSON.parse(JSON.stringify(this.subscription));
        this.localSubscription.entry_date = this.localSubscription.entry_date.substr(0, 10);
      }
    }

    private async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.localSubscription.entry_date = this.localSubscription.entry_date + ' 00:00:00';
        const success = await this.updateSubscription(this.localSubscription);
        this.localSubscription.entry_date = this.localSubscription.entry_date.substr(0, 10);

        if (success) {
          this.$emit('done');
          this.dialog = false;
          this.localSubscription = null;
          this.$refs.form.resetValidation();
        }

        this.loading = false;
      }
    }

  }
</script>

<style scoped>

</style>
