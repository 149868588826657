// @ts-ignore
export const addLoader = async ({ commit }) => {
  commit('addLoader');
};

// @ts-ignore
export const removeLoader = async ({ commit }) => {
  commit('removeLoader');
};

// @ts-ignore
export const start = async ({ commit }) => {
  commit('addLoader');
};

// @ts-ignore
export const stop = async ({ commit }) => {
  commit('removeLoader');
};

// @ts-ignore
export const addNavLoader = async ({ commit }) => {
  commit('addNavLoader');
};

// @ts-ignore
export const removeNavLoader = async ({ commit }) => {
  commit('removeNavLoader');
};
