<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl4>
        <v-form ref="form" @submit="Confirm" lazy-validation>
          <v-card class="elevation-12">
            <v-card-title primary-title class="primary">
              <div class="headline white--text">{{$t('auth.newPassword.header')}}</div>
            </v-card-title>
            <v-card-text>
              <p>
                {{$t('auth.newPassword.explanation')}}
              </p>
            </v-card-text>
            <v-card-text>
              <v-text-field
                autocomplete="email"
                prepend-icon="mail"
                name="email"
                :label="$t('auth.email')"
                :rules="[ rules.required, rules.email ]"
                :disabled="loading"
                v-model="user.username"
                type="text">
              </v-text-field>
              <v-text-field
                v-model="user.code"
                :disabled="loading"
                prepend-icon="person"
                name="code"
                :rules="[ rules.required, rules.code ]"
                :label="$t('auth.code')"
                type="text"
              ></v-text-field>
              <v-text-field
                autocomplete="new-password"
                id="newPassword"
                prepend-icon="lock"
                name="newPassword"
                :label="$t('auth.newPassword.title')"
                :rules="[ rules.required, rules.password ]"
                :disabled="loading"
                v-model="user.newPassword"
                type="password">
              </v-text-field>
              <router-link to="SignIn">{{$t('auth.login')}}</router-link>&nbsp;
              <router-link to="ForgotPassword">{{$t('auth.forgotPassword.title')}}</router-link>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                @click="RequestCode"
                :loading="loading"
                type="submit"
                :disabled="!this.user.username">
                {{$t('auth.newPassword.request')}}
              </v-btn>
              <v-btn
                color="primary"
                @click="Confirm"
                :loading="loading"
                type="submit">
                {{$t('auth.confirm')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  @Component
  export default class SignUp extends Vue {
    public $refs!: {
      form: any,
    };

    private user: any = {};
    private loading: boolean = false;
    @Getter('common/rules/all') private rules!: any;
    @Getter('guest/auth/username') private username!: any;
    @Getter('guest/auth/password') private password!: any;
    @Getter('guest/auth/oldPassword') private oldPassword!: any;

    @Action('guest/auth/signIn') private signIn!: any;
    @Action('guest/auth/forgotPassword') private forgotPassword!: any;
    @Action('guest/auth/forgotPasswordSubmit') private forgotPasswordSubmit!: any;
    @Action('guest/auth/resendSignUp') private resendSignUp!: any;
    @Action('guest/auth/confirmSignUp') private confirmSignUp!: any;

    private mounted() {
      if (this.username) {
        this.user.username = this.username;
      }
      if (this.oldPassword) {
        this.user.oldPassword = this.oldPassword;
      }
    }

    private async Confirm(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;
        const res = await this.forgotPasswordSubmit(this.user);
        if (res) {
          if (await this.signIn(this.user)) {
            this.$router.push({name: 'UserBase'});
          } else {
            this.$router.push({name: 'SignIn'});
          }
        }
        this.loading = false;
      }
    }

    private async RequestCode(e) {
      e.preventDefault();
      if (this.user.username) {
        this.loading = true;
        await this.forgotPassword(this.user);
        this.loading = false;
      }
    }
  }
</script>
<style scoped>

</style>
