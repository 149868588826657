import {API} from 'aws-amplify';

// @ts-ignore
export const create = async (post, commentText) => API.put(
  'slobodaUserApi',
  `/comments/${post.id}`,
  {body: {commentText}},
);

// @ts-ignore
export const del = async (comment) => API.del('slobodaUserApi', `/comments/${comment.id}`, {});
