<template>
  <v-container fluid grid-list-sm v-if="animal && !initLoading" class="pt-0">
    <v-layout row wrap>
      <v-flex xs12>
        <card :title="animal.title"
              color="primary" fluid>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout row wrap>
              <v-flex
                xs12 md4
              >
                <image-selector
                  v-model="profileImage"
                  :url="animal.profileImage"
                  :rules="[]"
                ></image-selector>
              </v-flex>
              <v-flex
                xs12 md8
              >
                <v-card-text class="pb-0">
                  <v-text-field type="text" label="Title" v-model="animal.title" :disabled="animal.deleted"
                    :rules="[ rules.required ]"
                  />
                  <v-text-field type="number" label="Age" v-model.number="animal.age" :disabled="animal.deleted"
                    :rules="[ rules.required ]"
                  />
                  <v-text-field type="text" :label="'Short Description'" v-model="animal.shortDescription"
                    :disabled="animal.deleted"
                  />
                  <v-textarea :auto-grow="true" :label="'Long Description'"
                    v-model="animal.longDescription" :disabled="animal.deleted"
                  />
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-form>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click.native="update()"
              :loading="loading"
              :disabled="!valid"
              color="primary"
            >
              Update
            </v-btn>
          </v-card-actions>
        </card>
      </v-flex>
      <v-flex xs12>
        <v-tabs
          fixed-tabs
          v-model="activeTab"
          color="primary"
          dark
          style="border-radius: 10px 10px 0 0 "
        >
          <v-tab
            :key="0"
            ripple
          >
            Posts
          </v-tab>
          <v-tab
            :key="1"
            ripple
          >
            Subscribers
          </v-tab>
          <v-tab-item
            :key="0"
          >
            <v-card class="border-radius">
              <v-card-text>
                <v-layout row wrap>
                  <v-flex v-for="post in animal.posts" :key="post.id" xs6 sm4 lg3 xl2 d-flex class="pa-2">
                    <router-link :to="{ name: 'AdminAnimalPost', params: { postId: post.id } }" class="clickable">
                      <v-card flat tile class="d-flex">
                        <s3-image v-if="post.images" :itemKey="post.images[0].replace('/full/', '/postthumb/')"
                          :border="true" :contain="false" />
                        <v-img src="img/playButton.png" alt="video" v-if="post.video" :contain="false" />
                      </v-card>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            :key="1"
          >
            <v-card class="border-radius">
              <v-card-text>
                <animal-subscriptions-table :subscriptions="animal.subscriptions"
                  class="mx-3 my-3"></animal-subscriptions-table>
                <vue-json-to-csv
                  :json-data="[...animal.subscriptions.map((subscription) => ({...subscription, user: subscription.user.email }))]"
                  :csv-title="'fileName'"
                  class="export-button-container"
                >
                  <v-btn color="primary" class="mt-3 mr-3">
                    Export <i class="mdi mdi-export-variant" aria-hidden="true"></i>
                  </v-btn>
                </vue-json-to-csv>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import ImageSelector from '@/components/ImageSelector.vue';
import Card from '@/components/material/Card.vue';
import S3Image from '@/components/S3Image.vue';
import S3Video from '@/components/S3Video.vue';
import AnimalSubscriptionsTable from '@/modules/admin/animals/_components/AnimalSubscriptionsTable.vue';
import PostCommentField from '@/modules/admin/comments/_components/PostCommentField.vue';
import PostLikeField from '@/modules/admin/likes/_components/PostLikeField.vue';
import InfiniteLoading from 'vue-infinite-loading';

import VueJsonToCsv from 'vue-json-to-csv';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
             components: {
               ImageSelector,
               Card,
               AnimalSubscriptionsTable,
               S3Video,
               PostCommentField,
               S3Image,
               PostLikeField,
               InfiniteLoading,
               VueJsonToCsv,
             },
           })
export default class List extends Vue {
  public $refs!: {
    form: any,
  };

  public valid: boolean = true;
  public loading: boolean = false;
  public initLoading: boolean = true;
  public activeTab: number | null = null;
  public profileImage: any = null;

  @Prop({type: String, default: ''}) private id!: string;

  @Getter('admin/animals/one') public animal!: any;
  @Getter('common/rules/all') private rules!: any;

  @Action('admin/animals/readOne') private readAnimal!: any;
  @Action('admin/animals/update') private updateAnimal!: any;
  @Action('admin/animals/read') private readAnimals!: any;
  @Action('admin/animals/uploadImage') private uploadImage!: any;

  private created() {
    this.initLoading = true;
    this.readAnimal({id: this.id})
      .then(() => {
        this.initLoading = false;
      })
      .catch(() => {
        this.initLoading = false;
      });
  }

  public async update() {
    if (this.$refs.form.validate()) {
      this.loading = true;

      if (this.profileImage) {
        this.animal.profileImage = await this.uploadImage(this.profileImage);
      }

      this.updateAnimal({
        id: this.animal.id,
        longDescription: this.animal.longDescription,
        profileImage: this.animal.profileImage,
        shortDescription: this.animal.shortDescription,
        subscriptionCount: this.animal.subscriptions.length,
        title: this.animal.title,
        age: this.animal.age<0?0:this.animal.age,
      })
        .then((success: any) => {
          if (success) {
            return this.readAnimals();
          }
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
          this.$refs.form.resetValidation();
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}


</script>

<style lang="scss" scoped>
.border-radius {
  border-radius: 0 0 10px 10px !important;
}

.export-button-container {
  display: flex;
  justify-content: flex-end;
}

.profile-image {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.img-blur {
  filter: blur(4px);
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
}

.clickable {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 450px;
  max-width: 935px;
  width: 100%;
  background: white;
  display: flex;
  align-content: flex-start;
  flex-flow: row nowrap;

  &-wrapper {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    color: rgb(232, 232, 232);
    z-index: 2;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  &-post {
    &-content {
      width: 100%;
      height: 72px;
      padding: 16px;
      border-bottom: 1px solid #efefef;
    }

    &-comment {
      padding: 16px;
      height: 50px;

      &-wrapper {
        height: 250px;
        border-bottom: 1px solid #efefef;
        width: 100%;
        overflow: hidden;

        &-layout {
          overflow-y: scroll;
          overflow-x: hidden;
          width: calc(100% + 17px);
          height: 100%;
        }
      }

      p {
        margin-left: 7px;
        color: #262626;
        margin-bottom: 0;
      }

      &-time {
        margin-left: 7px;
        color: #262626;
        font-size: 12px;
      }

      &-add {
        margin-top: auto;
      }
    }

    &-name {
      color: #262626;
      margin-left: 16px;
      font-size: 14px;
      font-weight: 400;
    }

    &-avatar {
      .v-image {
        height: 32px;
        width: 32px;
        border-radius: 50%;
      }
    }

    &-img {
      max-width: 600px;
      width: 100%;
      height: 100%;
      max-height: 450px;
      min-height: 290px;
      background-color: #000;
      border-right: 1px solid #efefef;
      display: flex;
      align-items: center;

      .v-image {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .modal {
    flex-flow: column nowrap;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &-post {
      &-content {
        padding: 0;
        margin: 0;
        height: 50px;
        justify-content: flex-start;
      }

      &-img {
        border-right: 0;
      }
    }
  }
}

</style>
