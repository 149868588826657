<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="sidebar"
    app
    dark
    absolute
    temporary
    mobile-break-point="991"
    width="260"
  >
    <v-list
      class="fill-height"
      prop="v-list"
      column
    >
      <v-list-tile avatar>
        <v-list-tile-avatar
          color="white"
        >
          <v-img
            :src="logo"
            contain
          />
        </v-list-tile-avatar>
        <v-list-tile-title class="title">
          Sloboda Zvierat
        </v-list-tile-title>
      </v-list-tile>
      <v-divider/>
      <v-list-tile
        v-for="(item, i) in items"
        :key="i"
        :to="{ name: item.route }"
        avatar
        class="v-list-item"
        active-class="primary"
      >
        <v-list-tile-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-tile-action>
        <v-list-tile-title
          v-text="$t(item.text)"
        />
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserNavigation',
  data: () => ({
    logo: '/img/logo.png',
    sidebarImage: '/img/pet_sidebar.jpg',
    items: [
      { icon: 'dashboard', text: 'user.feed.feed', route: 'UserFeed' },
      { icon: 'pets', text: 'user.animals.animals', route: 'UserAnimals' },
    ],
    responsive: false,
  }),
  computed: {
    ...mapGetters({
      isAdmin: 'guest/auth/isAdmin',
    }),
    sidebar: {
      get() {
        return this.$store.getters['common/ui/sidebar'];
      },
      set(value) {
        this.$store.dispatch('common/ui/setSidebar', value);
      },
    },
  },
  created() {
    this.sidebar = false;
  },
};
</script>

<style lang="scss">
  #app-drawer {
    .v-list__tile {
      border-radius: 4px;

      &--buy {
        margin-top: auto;
        margin-bottom: 17px;
      }
    }

    .v-image__image--contain {
      top: 9px;
      height: 60%;
    }

    .search-input {
      margin-bottom: 30px !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
</style>
