<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-btn
      slot="activator"
      fixed
      fab
      bottom
      right
      color="primary"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-card>
      <v-card-title class="primary">
        <span class="headline white--text">Create new Post</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step editable :complete="e1 > 1" step="1">Select Animal</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable :complete="e1 > 2" step="2">Add Image</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step editable step="3">Write Text</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-layout row wrap>
                  <v-flex
                    v-for="(animal) in animals"
                    :key="animal.id"
                    xs4 md3
                    class="pa-3 text-xs-center"
                  >

                    <v-avatar
                      color="grey lighten-3"
                      :class="{ 'selected': selected === animal.id, 'dark': selected !== animal.id }"
                      class="animal-avatar"
                      @click="selectAnimal(animal)"
                    >
                      <s3-image :itemKey="animal.profileImage.replace('/full/', '/small/')" class="elevation-6"/>
                    </v-avatar>
                    <div class="mt-2">
                      <span class="title">{{animal.title}}</span>
                    </div>
                  </v-flex>
                </v-layout>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-layout>
                  <v-flex xs12 sm8 offset-sm2 md6 offset-md3 lg4 offset-lg4>
                    <image-selector v-model="postImage" @input="e1 = 3" v-if="!postFile"/>
                    <video-selector v-model="postFile" @input="e1 = 3" v-if="!postImage"/>
                  </v-flex>
                </v-layout>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-textarea
                      v-model="post.content"
                      :rules="[rules.required]"
                      label="Text"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12 class="text-xs-right">
                    <v-btn
                      color="primary"
                      @click.native="save()"
                      :loading="loading"
                      :disabled="!valid"
                      class="mb-3"
                    >
                      Create Post
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';
  import ImageSelector from '@/components/ImageSelector.vue';
  import S3Image from '@/components/S3Image.vue';
  import VideoSelector from '@/components/VideoSelector.vue';

  @Component({
    components: {VideoSelector, S3Image, ImageSelector},
  })
  export default class DlgPostsNewMobile extends Vue {
    public $refs!: {
      form: any,
    };

    @Action('admin/posts/create') private createPost!: any;
    @Action('admin/posts/read') private readPosts!: any;
    @Action('admin/animals/read') private readAnimals!: any;
    @Action('admin/posts/uploadImage') private uploadImage!: any;
    @Action('admin/posts/uploadVideo') private uploadVideo!: any;
    @Getter('common/rules/all') private rules!: any;
    @Getter('admin/animals/all') private animals!: any;

    private post: any = {};
    private postImage: any = null;
    private postFile: any = null;
    private dialog: boolean = false;
    private loading: boolean = false;
    private valid: boolean = true;
    private selected: string = '';
    private e1: number = 1;

    @Watch('dialog')
    private async changedDialogValue(val: boolean) {
      if (val) {
        this.loading = true;
        await this.readAnimals();
        this.loading = false;
      }
    }

    private async save() {
      if (this.$refs.form.validate() && this.selected) {
        this.loading = true;

        this.post.animalId = this.selected;
        if (this.postImage) {
          this.post.images = [await this.uploadImage({animalId: this.post.animalId, image: this.postImage})];
        } else if (this.postFile) {
          this.post.video = await this.uploadVideo({animalId: this.post.animalId, video: this.postFile});
        }

        this.createPost(this.post)
          .then((success: any) => {
            if (success) {
              return this.readPosts();
            }
            throw new Error();
          })
          .then(() => {
            this.loading = false;
            this.dialog = false;
            this.post = {};
            this.postImage = null;
            this.selected = '';
            this.e1 = 1;
            this.$refs.form.resetValidation();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }

    private selectAnimal(animal) {
      this.selected = animal.id;
      this.e1 = 2;
    }
  }
</script>

<style scoped>
  .animal-avatar {
    cursor: pointer;
    transition: 0.3s all;
  }

  .dark {
    -webkit-filter: grayscale(100%) brightness(70%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) brightness(70%);
  }

  .dark:hover {
    -webkit-filter: none; /* Safari 6.0 - 9.0 */
    filter: none;
  }

</style>
