import Vue from 'vue';

Vue.filter('stringToDate', (value: string) => {
  if (!value) {
    return '<n/a>';
  }
  const date = new Date(value);

  return `${date.getDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()} - `
    + `${date.getUTCHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getUTCMinutes()}`;
});
