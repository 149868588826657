<template>
  <div>
    <v-btn
      class="delete-btn"
      icon
      @click.prevent="dialog = true"
      slot="activator"
    >
      <v-icon color="error">block</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Mark animal as adopted</span>
        </v-card-title>
        <v-card-text>
          Do you really want to mark "{{ animal.title }}" as adopted? Images and Posts will not be deleted but you will
          not
          be able to add any more posts for this animal.
        </v-card-text>
        <v-card-actions class="mb-3">
          <v-spacer></v-spacer>
          <v-btn class="mb-3" color="error" @click.native="del()" :loading="loading">Mark as adopted</v-btn>
          <v-btn class="mb-3" color="primary" @click.native="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import {mapActions} from 'vuex';


export default {
  name: 'DlgAnimalsDelete',
  components: {
    // VueEditor,
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  props: ['animal'],
  methods: {
    ...mapActions({
      memberDel: 'moderator/animals/del',
      memberRead: 'moderator/animals/read',
    }),
    del() {
      this.loading = true;
      this.memberDel(this.animal)
        .then((res) => {
          this.loading = false;
          if (res) {
            this.memberRead();
            this.dialog = false;
          }
        });
    },
  },
};
</script>

<style scoped>
.delete-btn:hover {
  background-color: lightgray;
}
</style>
