import * as API from '../_api';

import uuidv4 from 'uuid/v4';
import i18n from '@/i18n';

const randomId = () => `_${Math.random().toString(36).substr(2, 9)}`;

// @ts-ignore
export const create = async ({ dispatch }, one) => {
  try {
    await API.create(one);
    dispatch('common/snackbar/success', 'Animal has been added', { root: true });
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const read = async ({ commit, dispatch }) => {
  try {
    dispatch('common/loader/addNavLoader', null, { root: true });
    const all = await API.read();
    commit('setAll', all);
    dispatch('common/loader/removeNavLoader', null, { root: true });
    return true;
  } catch (ex) {
    dispatch('common/loader/removeNavLoader', null, { root: true });
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const readOne = async ({ commit, dispatch, state }, { id }) => {
  try {
    dispatch('common/loader/addNavLoader', null, { root: true });
    const one = await API.readOne(id);
    commit('setOne', one);
    dispatch('common/loader/removeNavLoader', null, { root: true });
    return true;
  } catch (ex) {
    dispatch('common/loader/removeNavLoader', null, { root: true });
    dispatch('common/snackbar/error', i18n.t('user.error'), { root: true });
    return false;
  }
};

// @ts-ignore
export const update = async ({ dispatch }, one) => {
  try {
    await API.update(one);
    dispatch('common/snackbar/success', 'Animal has been updated', { root: true });
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const del = async ({ dispatch }, one) => {
  try {
    await API.del(one);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const uploadImage = async ({ dispatch }, image) => {
  try {
    const uuid = uuidv4();
    const filename = `animal/full/${uuid}.${image.name.split('.').pop()}`;
    await API.uploadImage(filename, image);
    return filename;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};
