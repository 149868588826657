// @ts-ignore
export const toggleSidebar = async ({ commit }) => {
  commit('toggleSidebar');
  return true;
};

// @ts-ignore
export const setSidebar = async ({ commit }, value) => {
  commit('setSidebar', value);
  return true;
};
