import {API} from 'aws-amplify';

// @ts-ignore
export const read = async () => API.get('slobodaAdminApi', '/moderators', {});
// @ts-ignore
export const create = async (moderator) => API.put('slobodaAdminApi', '/moderators', {body: moderator});
// @ts-ignore
export const del = async (moderator) => API.del('slobodaAdminApi', '/moderators', {body: moderator});
// @ts-ignore
export const forgot = async (moderator) => API.post('slobodaAdminApi', '/moderators', {body: moderator});
