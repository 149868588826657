import moduleState from './_store/state';
import * as moduleActions from './_store/actions';
import * as moduleGetters from './_store/getters';
import * as moduleMutations from './_store/mutations';

export default {
  namespaced: true,
  state: moduleState,
  actions: moduleActions,
  mutations: moduleMutations,
  getters: moduleGetters,
};
