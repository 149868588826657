<template>
  <v-container>
    <v-layout>
      <v-flex xs12 md8 offset-md2 lg6 offset-lg3 v-if="post && animal && !loading">
        <post-card :post="post" :animal="animal" userType="user"/>
      </v-flex>
      <v-flex xs12 style="display:flex; justify-content: center; align-items: center" v-else>
        <loader />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Loader from '@/components/Loader.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PostCard from "@/components/Global/PostCard.vue";
import {Action, Getter} from "vuex-class";
import Animal from "@/types/Animal";

@Component({
  components: {Loader, PostCard}
})
export default class AnimalPost extends Vue {
  public loading: boolean = true;

  @Prop({type: String, default: ''}) private id!: string;
  @Prop({type: String, default: ''}) public postId!: string;

  @Action('user/posts/readOne') private readPost!: ({id}) => Promise<boolean>;
  @Action('user/animals/readOne') private readAnimal!: any;
  @Getter('user/posts/one') public post!: any;
  @Getter('user/animals/one') public animal!: Animal;

  private async created() {
    this.loading = true;
    await this.readPost({id: this.postId})
    await this.readAnimal({id: this.id});
    this.loading = false;
  }
}
</script>
