<template>
  <v-navigation-drawer
    v-model="sidebar"
    app
    temporary
  >
    <v-list>

      <router-link
        style="border-radius: 5px"
        v-for="(link, i) in links"
        :key="i"
        :to="link"
        class="no-link"
      >
        <v-list-tile>
          <v-list-tile-title v-text="$t(link.text)" />
        </v-list-tile>
      </router-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class GuestDrawer extends Vue {
  @Getter('guest/auth/isAdmin') private isAdmin!: any;

  private links: any = [
    {name: 'GuestHome', hash: '#welcome', text: ('guest.home')},
    {name: 'GuestAnimals', text: ('guest.animals')},
    {name: 'GuestHome', hash: '#about', text: ('guest.eAdoption')},
    {name: 'GuestHome', hash: '#impress', text: ('guest.contact')},
  ];

  private toggleDrawer() {
    this.sidebar = !this.sidebar;
  }

  private goToElement(element) {
    if (element.name === 'SignIn') {
      this.$router.push({name: 'SignIn'});
    } else if (this.$router.currentRoute.name === 'GuestHome') {
      this.$vuetify.goTo(element.to);
    } else {
      this.$router.push({name: 'GuestHome', hash: element.to});
    }
  }

  private get sidebar() {
    return this.$store.getters['common/ui/sidebar'];
  }

  private set sidebar(value) {
    this.$store.dispatch('common/ui/setSidebar', value);
  }
}
</script>

<style scoped>

</style>
