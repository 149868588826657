import * as API from '../_api';

export const read = async ({commit, dispatch}: any) => {
  try {
    const all = await API.read();
    commit('setAll', all);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', {root: true});
    return false;
  }
};

export const create = async ({commit, dispatch}: any, subscription: any) => {
  try {
    await API.create(subscription);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', {root: true});
    return false;
  }
};

export const update = async ({commit, dispatch}: any, subscription: any) => {
  try {
    await API.update(subscription);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', {root: true});
    return false;
  }
};

export const del = async ({commit, dispatch}: any, {id}: any) => {
  try {
    await API.del(id);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', {root: true});
    return false;
  }
};
