<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl4>
        <v-form ref="form" @submit="signUp" lazy-validation>
          <v-card class="elevation-12">
            <v-card-title primary-title class="primary">
              <div class="headline white--text">{{$t('auth.signUp.title')}}</div>
            </v-card-title>
            <v-card-text>
              <!--              <v-text-field-->
              <!--                autocomplete="username"-->
              <!--                prepend-icon="person"-->
              <!--                name="username"-->
              <!--                :label="$t('auth.username')"-->
              <!--                :rules="[ rules.required ]"-->
              <!--                :disabled="loading"-->
              <!--                v-model="user.username"-->
              <!--                type="text">-->
              <!--              </v-text-field>-->
              <v-text-field
                autocomplete="email"
                prepend-icon="mail"
                name="email"
                :label="$t('auth.email')"
                :rules="[ rules.required, rules.email ]"
                :disabled="loading"
                v-model="user.username"
                type="text">
              </v-text-field>
              <v-text-field
                autocomplete="new-password"
                id="password"
                prepend-icon="lock"
                name="password"
                :label="$t('auth.password')"
                :rules="[ rules.required, rules.password ]"
                :disabled="loading"
                v-model="user.password"
                type="password">
              </v-text-field>
              <v-checkbox
                color="primary"
                :rules="[ rules.required ]"
              >
                <span slot="label">
                  <a href="/SZ_SK_AGB_online_adoption.pdf" target="_blank">{{$t('auth.agreement')}}</a>
                </span>
              </v-checkbox>
              <router-link :to="{ name: 'SignIn' }">{{$t('auth.login')}}</router-link>
              &nbsp
              <router-link :to="{ name: 'ForgotPassword' }">{{$t('auth.forgotPassword.title')}}</router-link>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="signUp"
                :loading="loading"
                type="submit">
                {{$t('auth.signUp.title')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  import {mapActions} from 'vuex';

  @Component
  export default class SignUp extends Vue {
    public $refs!: {
      form: any,
    };

    private user: any = {};
    private loading: boolean = false;
    @Getter('common/rules/all') private rules!: any;

    @Action('guest/auth/signUp') private storeSignUp!: (user: any) => Promise<boolean>;
    @Action('guest/auth/signIn') private storeSignIn!: (user: any) => Promise<boolean>;

    private async signUp(e: Event): Promise<void> {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;

        const success = await this.storeSignUp(this.user);

        if (success) {
          await this.storeSignIn(this.user);
          this.$router.push({name: 'UserBase'});
        }

        this.loading = false;
      }
    }
  }
</script>

<style scoped>

</style>
