<template>
  <v-form ref="form" @submit="postComment" lazy-validation>
    <v-textarea
      v-model="commentText"
      auto-grow
      :label="`${$t('user.comments.comment')}...`"
      rows="1"
      append-icon="send"
      @click:append="postComment"
      :disabled="loading"
      :loading="loading"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="postComment"
      :rules="[ rules.required ]"
      lazy-validation
      hide-details
      flat
    ></v-textarea>
  </v-form>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action, Getter} from 'vuex-class';

  @Component
  export default class PostCommentField extends Vue {
    public $refs!: {
      form: any,
    };

    @Prop({type: String, default: 'user'}) public userType!: 'user' | 'admin' | 'moderator';
    @Prop({type: Object, default: {}}) private post!: any;
    @Prop({type: Function, default: () => ({})}) private callback!: any;
    public commentText: string = '';
    public loading: boolean = false;

    @Action('user/comments/create') private storePostComment!: ({post, commentText}) => Promise<boolean>;
    @Action('user/posts/readOne') private readPost!: ({id}) => Promise<boolean>;

    @Getter('common/rules/all') public rules!: any;

    public async postComment(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const succ = await this.storePostComment({post: this.post, commentText: this.commentText});
          if (!succ) {
            // TODO: add translation
            throw new Error('Error while creating comment');
          }
          const post = await this.readPost(this.post);
          this.$emit('update', post);
          this.callback(this.commentText);
          this.commentText = '';
          this.$refs.form.resetValidation();
          this.loading = false;
        } catch (ex) {
          this.loading = false;
        }
      }
    }
  }
</script>

<style scoped>

</style>
