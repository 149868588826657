import guest from './guest';
import user from './user';
import moderator from './moderator';
import admin from './admin';
import common from './common';

export default {
  guest,
  user,
  moderator,
  admin,
  common,
};
