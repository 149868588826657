import {Auth} from 'aws-amplify';

import AnimalPost from '@/views/Admin/Animals/Post.vue';
import Moderators from '@/views/Admin/Moderators/List.vue';
import Animals from '@/views/Admin/Animals/List.vue';
import Animal from '@/views/Admin/Animals/Details.vue';
import Posts from '@/views/Admin/Posts/List.vue';
import Users from '@/views/Admin/Users/List.vue';
import UserDetails from '@/views/Admin/Users/Details.vue';
import Subscriptions from '@/views/Admin/Subscriptions/List.vue';

function mustBeAdmin(to: any, from: any, next: any) {
  Auth.currentAuthenticatedUser().then((user) => {
    const role = user.signInUserSession.idToken.payload['cognito:groups'][0];
    if (role === 'Admin') {
      next();
    } else if (role === 'Moderator') {
      next({name: 'ModeratorBase'});
    } else {
      next({name: 'UserBase'});
    }
  }).catch(() => {
    next({name: 'GuestBase'});
  });
}

export default [
  {
    path: '/admin',
    name: 'AdminBase',
    component: () => import(/* webpackChunkName: "AdminBase" */ '../views/Admin/Layout.vue'),
    beforeEnter: mustBeAdmin,
    redirect: {name: 'AdminAnimals'},
    children: [
      {
        path: 'moderators',
        name: 'AdminModerators',
        component: Moderators,
      },
      {
        path: 'animals',
        name: 'AdminAnimals',
        component: Animals,
      },
      {
        path: 'animal/:id',
        props: true,
        name: 'AdminAnimal',
        component: Animal,
      },
      {
        path: 'animal/:id/post/:postId',
        name: 'AdminAnimalPost',
        component: AnimalPost,
        props: true,
      },
      {
        path: 'posts',
        name: 'AdminPosts',
        component: Posts,
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: Users,
      },
      {
        path: 'users/:id',
        props: true,
        name: 'AdminUser',
        component: UserDetails,
      },
      {
        path: 'subscriptions',
        props: true,
        name: 'AdminSubscriptions',
        component: Subscriptions,
      },
    ],
  },
];
