<template>
  <div class="text-xs-center">
    <img
      class="payment_img payment-error_img"
      src="/img/icons/redcross.png"
    />
    <h3 class="payment_subtitle">{{$t('pay.error.title')}}</h3>
    <h3 class="payment_subtitle">{{$t('pay.error.subtitle')}}</h3>
    <p class="payment_text">{{$t('pay.error.text')}} <a href="mailto:milacik@slobodazvierat.sk">milacik@slobodazvierat.sk</a></p>
    <v-btn
      :to="{ name: 'GuestBase' }"
      color="primary"
    >
      {{$t('pay.error.button')}}
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'Error',
  };
</script>

<style lang="scss" scoped>
  .payment {
    &_img {
      height: 150px;
      width: 150px;
      margin: 20px 0;
    }

    &-error {
      &_img {
        transform: rotate(45deg);
      }
    }

    &_subtitle {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.65);
    }

    &_text {
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.65);
      margin: 20px auto;
      width: 45%;
    }
  }
</style>
