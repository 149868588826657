<template>
  <v-toolbar
    id="toolbar"
    app
  >
    <v-toolbar-side-icon
      class="hidden-md-and-up"
      @click.stop="toggleSidebar"
    />
    <v-container
      mx-auto
      py-0
    >
      <v-layout>
        <v-img
          :src="require('@/assets/logo.png')"
          class="mr-5"
          contain
          height="48"
          width="48"
          max-width="48"
          style="cursor: pointer"
          @click="goToElement({to: '#welcome', text: ('guest.home')})"
        />

        <router-link
          v-for="(link, i) in links"
          :key="i"
          :to="link"
          class="ml-0 hidden-sm-and-down"
          active-class="primary--text"
        >
          <v-btn
            flat
          >
            {{ $t(link.text) }}
          </v-btn>
        </router-link>
        <v-spacer></v-spacer>

        <v-btn icon :to="{ name: 'UserSettings' }">
          <v-icon>settings</v-icon>
        </v-btn>
        <v-btn icon @click.stop="logout">
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-layout>
    </v-container>
  </v-toolbar>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'UserToolbar',
    data: () => ({
      dialog: false,
      logo: '/img/logo.png',
      title: null,
      responsiveInput: false,
      links: [
        {name: 'UserFeed', text: 'user.feed.feed'},
        {name: 'UserAnimals', text: 'user.animals.animals'},
      ],
    }),

    watch: {
      $route(val) {
        this.title = val.name;
      },
    },

    mounted() {
      this.title = this.$router.currentRoute.name;
    },

    computed: {
      ...mapGetters({
        username: 'guest/auth/username',
      }),
    },
    methods: {
      ...mapActions({
        signOut: 'guest/auth/signOut',
        toggleSidebar: 'common/ui/toggleSidebar',
      }),
      logout() {
        this.signOut()
          .then((success) => {
            if (success) {
              this.$router.push({name: 'SignIn'});
            }
          });
      },
      goToElement(element) {
        if (this.$router.currentRoute.name === 'GuestHome') {
          this.$vuetify.goTo(element.to);
        } else {
          this.$router.push({name: 'GuestHome', hash: element.to});
        }
      },
    },
  };
</script>

<style scoped lang="scss">
.theme--light.v-toolbar {
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgb(241, 241, 241);
}

  .btn-link-active {
    color: #00bcd4 !important;
  }
</style>
