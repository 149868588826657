import {API, Cache} from 'aws-amplify';

export const read = async () => {
  const cache = Cache.getItem('guestAnimals');

  if (cache) {
    return cache;
  }

  const animals = await API.get('slobodaGuestApi', '/animals', {});
  const date = new Date();
  await Cache.setItem('guestAnimals', animals, { expires: date.getTime() + 10000 });
  return animals;
};

export const readOne = async (id) => {
  const cache = Cache.getItem('guestAnimal-' + id);

  if (cache) {
    return cache;
  }

  const animal = await API.get('slobodaGuestApi', `/animals/${id}`, {});
  const date = new Date();
  await Cache.setItem('guestAnimal-' + id, animal, { expires: date.getTime() + 10000 });
  return animal;
};
