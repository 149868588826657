<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
  >
    <v-btn
      slot="activator"
      fixed
      fab
      bottom
      right
      color="primary"
      style="border-radius: 100px !important;"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-card>
      <v-card-title class="primary">
        <span class="headline white--text">Create new animal</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12 sm4 offset-sm4
              >
                <image-selector v-model="profileImage"/>
              </v-flex>
              <v-flex
                xs12
              >
                <v-text-field
                  v-model="animal.title"
                  :rules="[rules.required]"
                  :disabled="loading"
                  label="Title *"
                  required
                />
              </v-flex>
              <v-flex
                xs12
              >
                <v-text-field
                  type="number"
                  v-model.number="animal.age"
                  :disabled="loading"
                  label="Age"
                />
              </v-flex>
              <v-flex
                xs12
              >
                <v-textarea
                  v-model="animal.shortDescription"
                  :rules="[rules.required]"
                  label="Description Short *"
                  :disabled="loading"
                ></v-textarea>
              </v-flex>
              <v-flex
                xs12
              >
                <v-textarea
                  v-model="animal.longDescription"
                  :rules="[rules.required]"
                  label="Description Long *"
                  :disabled="loading"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <small>*Required</small>
      </v-card-text>
      <v-card-actions class="mb-3">
        <v-spacer/>
        <v-btn
          @click.native="dialog = false"
          class="mb-3"
        >
          Cancel
        </v-btn>
        <v-btn
          class="mb-3"
          color="primary"
          @click.native="save()"
          :loading="loading"
          :disabled="!valid||!profileImage"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';
  import ImageSelector from '@/components/ImageSelector.vue';

  @Component({
    components: {ImageSelector},
  })
  export default class DlgAnimalsNew extends Vue {
    public $refs!: {
      form: any,
    };

    @Action('admin/animals/create') private createAnimal!: any;
    @Action('admin/animals/read') private readAnimals!: any;
    @Action('admin/animals/uploadImage') private uploadImage!: any;
    @Getter('common/rules/all') private rules!: any;

    private animal: any = {};
    private galleryImages: any = [null];
    private profileImage: any = null;
    private dialog: boolean = false;
    private loading: boolean = false;
    private valid: boolean = true;

    private async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        // Step 1: upload all the images
        this.animal.profileImage = await this.uploadImage(this.profileImage);
        // Step 2: add urls to profileImage and previewImage Array

        // Step 3: save animal

        this.createAnimal({...this.animal, age: this.animal.age<0 ? 0 : this.animal.age})
          .then((success: any) => {
            if (success) {
              return this.readAnimals();
            }
            throw new Error();
          })
          .then(() => {
            this.loading = false;
            this.dialog = false;
            this.animal = {};
            this.$refs.form.resetValidation();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }

  }
</script>

<style scoped>

</style>
