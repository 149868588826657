<template>
  <v-container >
    <v-layout>
      <v-flex v-if="post && animal">
        <post-card :post="post" :animal="animal" userType="moderator"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Post from '@/types/Post';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PostCard from "@/components/Global/PostCard.vue";
import {Action, Getter} from "vuex-class";
import Animal from "@/types/Animal";

@Component({
  components: {PostCard}
})
export default class AnimalPost extends Vue {

  @Prop({type: String, default: ''}) private id!: string;
  @Prop({type: String, default: ''}) public postId!: string;

  @Action('moderator/posts/readOne') private readPost!: ({id}) => Promise<void>;
  @Action('moderator/animals/readOne') private readAnimal!: ({id}) => Promise<void>;

  @Getter('moderator/posts/one') public post!: Post;
  @Getter('moderator/animals/one') public animal!: Animal;

  private async created() {
    await this.readPost({id: this.postId})
    await this.readAnimal({id: this.id});
  }
}
</script>
