import auth from './auth';
import animals from './animals';

export default {
  namespaced: true,
  modules: {
    auth,
    animals,
  },
};
