<template>
  <v-dialog v-model="diag" max-width="800px">

    <v-tooltip bottom slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <v-icon color="error">delete</v-icon>
        </v-btn>
      </template>
      <span>Zrušiť predplatné</span>
    </v-tooltip>
    <v-card>
      <v-card-title class="primary" >
        <span class="headline white--text">Zrušiť predplatné</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="diag = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="my-3">
        Naozaj chcete zrušiť svoje predplatné?
        Už vám nebudú účtované žiadne poplatky, prístup do adopčného systému bude zrušený.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click.native="del()"
          :loading="loading"
          class="mb-3"
        >
          Zrušiť predplatné
        </v-btn>
        <v-btn
          @click.native="diag = false"
          class="mb-3"
          color="primary"
        >
          Nie
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action} from 'vuex-class';

  @Component
  export default class DlgSubscriptionsDelete extends Vue {
    @Prop({type: Object, default: null}) private subscription!: any;

    public loading: boolean = false;
    public diag: boolean = false;

    @Action('user/subscriptions/del') private subscriptionDel!: any;
    @Action('user/subscriptions/read') private subscriptionRead!: any;

    public del() {
      this.loading = true;

      this.subscriptionDel(this.subscription).then((res) => {
        this.loading = false;
        if (res) {
          this.subscriptionRead();
          this.diag = false;
        }
      });
    }
  }
</script>

<style scoped>

</style>
