import {API} from 'aws-amplify';

// @ts-ignore
export const read = async () => API.get('slobodaAdminApi', '/subscriptions', {});
// @ts-ignore
export const create = async (subscription) => API.put('slobodaAdminApi', '/subscriptions', {body: subscription});
// @ts-ignore
export const del = async (id) => API.del('slobodaAdminApi', `/subscriptions/${id}`, {});
// @ts-ignore
export const update = async (subscription) =>
  API.post('slobodaAdminApi', `/subscriptions/${subscription.id}`, {body: subscription});
