<template>
  <v-container>
    <card
      title="User"
      color="primary"
    >
      <!--{{one}}-->
      <v-layout row wrap v-if="!loading && one.user">
        <v-flex
          xs6
          v-for="attribute in one.user.UserAttributes"
          :key="attribute.Name"
          class="pr-2 pl-2"
        >
          <v-text-field type="text" :label="$t(attribute.Name)" :value="attribute.Value" disabled></v-text-field>
        </v-flex>
      </v-layout>
    </card>
    <card
      title="Subscriptions"
      color="primary"
    >
      <!--{{one}}-->
      <v-layout row wrap v-if="!loading && one.subscriptions">
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="one.subscriptions"
            class="elevation-1"
            hide-actions
            :rows-per-page-items="[15, 10, 5]"
          >
            <template v-slot:items="props">
              <tr :class="{ 'deleted': props.item.deleted }">
                <td>{{ props.item.entry_date }}</td>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.deleted?'Deleted':'Active' }}</td>
                <td>
                  <router-link :to="{ name: 'AdminAnimal', params: { id: props.item.animalId }}" class="link">
                    {{ getAnimal(props.item.animalId) }}
                  </router-link>
                </td>
                <td>{{ props.item.var_sym }}</td>
                <td>
                  <dlg-subscriptions-edit :subscription="props.item" @done="init" />
                  <dlg-subscriptions-delete :subscription="props.item" v-if="!props.item.deleted" @done="init" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex xs12 >
          <dlg-subscriptions-create class="mt-3" style="display: flex; justify-content: end" :userId="id" @done="init"/>
        </v-flex>
      </v-layout>
    </card>
  </v-container>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';
  import S3Image from '@/components/S3Image.vue';
  import Card from '@/components/material/Card.vue';
  import DlgSubscriptionsDelete from '@/modules/admin/subscriptions/_components/DlgSubscriptionsDelete.vue';
  import DlgSubscriptionsEdit from '@/modules/admin/subscriptions/_components/DlgSubscriptionsEdit.vue';
  import DlgSubscriptionsCreate from '@/modules/admin/subscriptions/_components/DlgSubscriptionsCreate.vue';

  @Component({
    components: {
      DlgSubscriptionsCreate,
      DlgSubscriptionsEdit,
      DlgSubscriptionsDelete,
      Card,
      S3Image,
    },
  })
  export default class UsersList extends Vue {
    @Prop({type: String, default: ''}) private id!: string;

    @Action('admin/users/readOne') private readUser!: (id: string) => Promise<boolean>;
    @Action('admin/animals/read') private readAnimals!: () => Promise<boolean>;
    @Action('common/loader/addNavLoader') private startLoading!: () => null;
    @Action('common/loader/removeNavLoader') private stopLoading!: () => null;

    @Getter('admin/animals/all') private animals!: any;
    @Getter('admin/users/one') private one!: any;

    private loading: boolean = false;
    private headers: any = [
      {text: 'Last Payment', value: 'entry_date'},
      {text: 'ID', value: 'id'},
      {text: 'Status', value: 'deleted'},
      {text: 'Animal', value: 'animalId'},
      {text: 'Var Sym', value: 'var_sym'},
      {text: '', value: ''},
    ];

    private async mounted() {
      this.init();
    }

    private async init() {
      this.loading = true;
      this.startLoading();
      await this.readAnimals();
      await this.readUser(this.id);
      this.stopLoading();
      this.loading = false;
    }

    private getAnimal(id: string) {
      const filtered = this.animals.filter((animal) => animal.id === id)[0];

      if (filtered) {
        return `${filtered.title}${filtered.deleted ? ' (Deleted)' : ''}`;
      } else {
        return '- deleted -';
      }
    }
  }
</script>

<style scoped>
  tr.deleted {
    background-color: #FFC7CE;
    color: #9C0006;
  }

  .link{
    font-family: Arial, sans-serif;
    font-weight: bold;
  }

  .link:hover {
    text-decoration: underline;
  }
</style>
