/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "slobodaAnimals-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaComments-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaLikes-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaPosts-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaSubscriptions-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaUserData-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaTransactionLog-master",
            "region": "eu-west-1"
        },
        {
            "tableName": "slobodaPaymentLog-master",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "slobodae4e445ed49294cb59c51f615a8e56e17-master",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:57a70d46-e238-491f-a5bf-80cfa208482f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_OPrFPEqmL",
    "aws_user_pools_web_client_id": "218kdk2rt2ap57rofvm5onpfqg",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "slobodaAdminApi",
            "endpoint": "https://aw4tm6ww14.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "slobodaUserApi",
            "endpoint": "https://5sj58flqdb.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "slobodaModeratorApi",
            "endpoint": "https://zb8i23uri4.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "slobodaPaymentApi",
            "endpoint": "https://sksaq0bks0.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "slobodaGuestApi",
            "endpoint": "https://b4q5tw6o9k.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "sloboda-20190527235731-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://sloboda-20190527235731-hostingbucket-master.s3-website-eu-west-1.amazonaws.com",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
