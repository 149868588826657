<template>
  <div>
    <video v-if="src" controls width="100%" height="auto" preload="metadata" :autoplay="autoplay">
      <source :src="src">
    </video>
    <div v-else style="">
      Loading...
    </div>
  </div>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {Storage} from 'aws-amplify';

  @Component
  export default class S3Video extends Vue {
    public src: any = '';
    private lazySrc: any = '';

    @Prop({type: String, default: ''}) private itemKey!: string;
    @Prop({type: Boolean, default: false}) public autoplay!: boolean;
    @Prop({type: String, default: 'public'}) private level!: 'public' | 'protected' | 'private' | undefined;

    private created() {
      return this.init(this.itemKey);
    }

    private async init(itemKey: string) {
      try {
        this.src = await Storage.get(itemKey, {level: this.level, expires: 60 * 60 * 24 * 2, contentType: 'video/mp4'});
        console.log(this.src);
      } catch (e) {
        console.log(e);
      }
    }

    private error() {
      this.src = 'img/loader.gif';

      setTimeout(() => {
        this.init(this.itemKey);
      }, 5000);
    }

    @Watch('itemKey')
    private reloadItem() {
      this.init(this.itemKey);
    }

    private load() {
      // console.log('load');
    }
  }
</script>

<style scoped>
  .border {
    border-top: 1px solid rgb(239, 239, 239);
    border-bottom: 1px solid rgb(239, 239, 239);
  }
</style>
