import * as API from '../_api';

// @ts-ignore
export const read = async ({ commit, dispatch }) => {
  try {
    const all = await API.read();
    commit('setAll', all);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};

// @ts-ignore
export const readOne = async ({ commit, dispatch }, id) => {
  try {
    const one = await API.readOne(id);
    commit('setOne', one);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};
