import * as API from '../_api';

import uuidv4 from 'uuid/v4';
import i18n from '@/i18n';

const randomId = () => `_${Math.random().toString(36).substr(2, 9)}`;

// @ts-ignore
export const create = async ({ dispatch }, one) => {
  // TODO: image upload

  try {
    await API.create(one);
    dispatch('common/snackbar/success', 'Post has been added', { root: true });
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const read = async ({ commit, dispatch, state }) => {
  try {
    dispatch('common/loader/addNavLoader', null, { root: true });
    const all = await API.read(5);
    commit('setAll', all);
    dispatch('common/loader/removeNavLoader', null, { root: true });
    return true;
  } catch (ex) {
    dispatch('common/loader/removeNavLoader', null, { root: true });
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const readOne = async ({ commit, dispatch }, { id }) => {
  try {
    const one = await API.readOne(id);
    commit('setOne', one);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const update = async ({ dispatch }, one) => {
  try {
    await API.update(one);
    dispatch('common/snackbar/success', 'Post has been updated', { root: true });
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const del = async ({ dispatch }, post) => {
  try {
    await API.del(post);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const uploadImage = async ({ dispatch }, { animalId, image }) => {
  try {
    const uuid = uuidv4();
    const filename = `post/${animalId}/full/${uuid}.${image.name.split('.').pop()}`;
    await API.uploadImage(filename, image);
    return filename;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const uploadVideo = async ({ dispatch }, { animalId, video }) => {
  try {
    const uuid = uuidv4();
    const filename = `post/${animalId}/video/${uuid}.${video.name.split('.').pop()}`;
    await API.uploadVideo(filename, video);
    return filename;
  } catch (ex) {
    dispatch('common/snackbar/error', ex, { root: true });
    return false;
  }
};

// @ts-ignore
export const infiniteRead = async ({ commit, dispatch, state }) => {
  try {
    dispatch('common/loader/addNavLoader', null, { root: true });
    const all = await API.read(2, state.lastEvaluatedKey);
    commit('concatPosts', all);
    dispatch('common/loader/removeNavLoader', null, { root: true });
    return true;
  } catch (ex) {
    dispatch('common/loader/removeNavLoader', null, { root: true });
    dispatch('common/snackbar/error', i18n.t('user.error'), { root: true });
    return false;
  }
};
