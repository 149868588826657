import i18n from '@/i18n';

export default {
  // @ts-ignore
  required: (value) => !!value || i18n.t('inputRules.required'),
  // @ts-ignore
  email: (value) => {
    // tslint:disable-next-line:max-line-length
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || i18n.t('inputRules.invalidEmail');
  },
  // @ts-ignore
  afterToday: (value) => {
    if (!value) {
      return true;
    }
    const [day, month, year] = value.split('.');
    const dt1 = new Date();
    const dt2 = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));

    return +dt1 < +dt2 || 'Datum muss in der Zukunft liegen';
  },
  minimumPayment: (value) => (parseFloat(value) >= 1) || i18n.t('inputRules.minimumPayment'),
  password: (value) => (value && value.length >= 8) || i18n.t('inputRules.shortPassword'),
  code: (value) => (value && value.length === 6 && /^\d+$/.test(value)) || i18n.t('inputRules.invalidCode'),

};
