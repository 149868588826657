<template>
  <router-link :to="{ name: 'UserAnimalPost', params: { postId: post.id } }" class="no-link">
    <v-card flat tile class="d-flex">
      <s3-image v-if="post.images" :itemKey="post.images[0].replace('/full/', '/postthumb/')"
        :border="false" />
      <v-img src="img/playButton.png" alt="video" v-if="post.video" :contain="false" />
    </v-card>
  </router-link>
</template>

<script lang="ts">
import S3Image from '@/components/S3Image.vue';
import S3Video from '@/components/S3Video.vue';
import Animal from '@/types/Animal';
import Post from '@/types/Post';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import PostCommentField from '@/modules/user/comments/_components/PostCommentField.vue';
import PostLikeField from '@/modules/user/likes/_components/PostLikeField.vue';

@Component({
             components: {
               S3Image,
               PostCommentField,
               PostLikeField,
               S3Video,
             },
           })
export default class AnimalCard extends Vue {
  @Prop({default: {id: '', video: '', images: []}}) public post!: Post;
}
</script>

<style scoped lang="scss">
.img-blur {
  filter: blur(10px);
}


.clickable {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 450px;
  max-width: 935px;
  width: 100%;
  background: white;
  display: flex;
  align-content: flex-start;
  flex-flow: row nowrap;

  &-wrapper {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    color: rgb(232, 232, 232);
    z-index: 2;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  &-post {
    &-content {
      width: 100%;
      height: 72px;
      padding: 16px;
      border-bottom: 1px solid #efefef;
    }

    &-comment {
      padding: 16px;
      height: 50px;

      &-wrapper {
        height: 250px;
        border-bottom: 1px solid #efefef;
        width: 100%;
        overflow: hidden;

        &-layout {
          overflow-y: scroll;
          overflow-x: hidden;
          width: calc(100% + 17px);
          height: 100%;
        }
      }

      p {
        margin-left: 7px;
        color: #262626;
        margin-bottom: 0;
      }

      &-time {
        margin-left: 7px;
        color: #262626;
        font-size: 12px;
      }

      &-add {
        margin-top: auto;
      }
    }

    &-name {
      color: #262626;
      margin-left: 16px;
      font-size: 14px;
      font-weight: 400;
    }

    &-avatar {
      .v-image {
        height: 32px;
        width: 32px;
        border-radius: 50%;
      }
    }

    &-img {
      max-width: 600px;
      width: 100%;
      height: 100%;
      max-height: 450px;
      min-height: 290px;
      background-color: #000;
      border-right: 1px solid #efefef;
      display: flex;
      align-items: center;

      .v-image {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .modal {
    flex-flow: column nowrap;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &-post {
      &-content {
        padding: 0;
        margin: 0;
        height: 50px;
        justify-content: flex-start;
      }

      &-img {
        border-right: 0;
      }
    }
  }
}
</style>
