import {API, Storage} from 'aws-amplify';

// @ts-ignore
function cleanEmptyValue(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === '') {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj;
}

// @ts-ignore
export const create = async (animal) => API.put(
  'slobodaAdminApi',
  '/animals',
  {body: cleanEmptyValue(animal)},
);

export const read = async () => API.get('slobodaAdminApi', '/animals', {});

export const readOne = async (id) => API.get('slobodaAdminApi', `/animals/${id}`, {});

// @ts-ignore
export const update = async (animal) => API.post('slobodaAdminApi', `/animals/${animal.id}`, {
  body:
    cleanEmptyValue(animal),
});

// @ts-ignore
export const del = async (animal) => API.del('slobodaAdminApi', `/animals/${animal.id}`, {});

// @ts-ignore
export const uploadImage = (name, image) => {
  // TODO: check if file exists already
  return Storage.put(name, image, { level: 'public', contentType: 'image/png' });
};
