<template>
  <div>
    <v-container>
      <card
        color="primary"
        title="Moderators"
      >
        <v-data-table
          :headers="headers"
          :items="moderators"
          hide-actions
          :loading="loading"
          :rows-per-page-items="[10, 5]"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.username }}</td>
            <td>{{ props.item.email }}</td>
            <td>
              <v-chip class="primary lighten-3" disabled>
                <span class="white--text">{{ props.item.status }}</span>
              </v-chip>
            </td>
            <td width="156">
              <dlg-moderators-forgot :moderator="props.item"></dlg-moderators-forgot>
              <dlg-moderators-delete :moderator="props.item"></dlg-moderators-delete>
            </td>
          </template>
        </v-data-table>
      </card>
    </v-container>

    <dlg-moderators-new/>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter,} from 'vuex-class';
import S3Image from '@/components/S3Image.vue';
import DlgModeratorsNew from '@/modules/admin/moderators/_components/DlgModeratorsNew.vue';
import Card from '@/components/material/Card.vue';
import DlgModeratorsDelete from '@/modules/admin/moderators/_components/DlgModeratorsDelete.vue';
import DlgModeratorsForgot from '@/modules/admin/moderators/_components/DlgModeratorsForgot.vue';

@Component({
  components: {
    DlgModeratorsForgot,
    DlgModeratorsDelete,
    Card,
    DlgModeratorsNew,
    S3Image,
  },
})
export default class ModeratorsList extends Vue {
  private loading: boolean = false;
  @Action('admin/moderators/read') private readModerators!: () => Promise<boolean>;
  @Getter('admin/moderators/all') private moderators!: any[];

  private headers: any = [
    {
      text: 'ID',
      align: 'left',
      value: 'username',
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email',
    },
    {
      text: 'Status',
      align: 'left',
      sortable: false,
      value: 'status',
    },
    {
      text: '',
      align: 'left',
      sortable: false,
      value: '',
    },
  ];

  private mounted() {
    this.loading = true;

    this.readModerators()
      .then((success) => {
        if (success) {
          this.loading = false;
        } else {
          throw new Error('Some Error, handle it');
        }
      })
      .catch((err) => {
        this.loading = false;
      });
  }
}
</script>

<style scoped>

</style>
