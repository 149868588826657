<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-btn
      slot="activator"
      icon
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <v-card v-if="localAnimal">
      <v-card-title class="primary">
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <span class="headline white--text">Edit {{localAnimal.title}}</span>
        <v-spacer></v-spacer>
        <v-btn
          dark
          @click.native="update()"
          :loading="loading"
          :disabled="!valid"
        >
          Update
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12 sm4 offset-sm4
              >
                <image-selector
                  v-model="profileImage"
                  :url="localAnimal.profileImage"
                  :rules="[]"
                ></image-selector>
              </v-flex>
              <v-flex
                xs12
              >
                <v-text-field
                  v-model="localAnimal.title"
                  :rules="[rules.required]"
                  :disabled="loading"
                  label="Titel*"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
              >
                <v-textarea
                  v-model="localAnimal.shortDescription"
                  :rules="[rules.required]"
                  :disabled="loading"
                  label="Description Short"
                ></v-textarea>
              </v-flex>
              <v-flex
                xs12
              >
                <v-textarea
                  v-model="localAnimal.longDescription"
                  :rules="[rules.required]"
                  :disabled="loading"
                  label="Description Long"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-3">
        <v-spacer/>
        <v-btn
          color="primary"
          @click.native="update()"
          :loading="loading"
          :disabled="!valid"
          class="mb-3"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';
  import ImageSelector from '@/components/ImageSelector.vue';

  @Component({
    components: {ImageSelector},
  })
  export default class DlgAnimalsEdit extends Vue {
    public $refs!: {
      form: any,
    };

    @Prop({type: Object, default: null}) private animal!: any;
    @Action('admin/animals/update') private updateAnimal!: any;
    @Action('admin/animals/read') private readAnimals!: any;
    @Action('admin/animals/uploadImage') private uploadImage!: any;
    @Getter('common/rules/all') private rules!: any;

    private localAnimal: any = null;
    private dialog: boolean = false;
    private loading: boolean = false;
    private valid: boolean = true;
    private profileImage: any = null;

    @Watch('dialog')
    private onDialogChanged(val: boolean) {
      if (val) {
        this.localAnimal = JSON.parse(JSON.stringify(this.animal));
      }
    }

    private async update() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        if (this.profileImage) {
          this.localAnimal.profileImage = await this.uploadImage(this.profileImage);
        }

        this.updateAnimal(this.localAnimal)
          .then((success: any) => {
            if (success) {
              return this.readAnimals();
            }
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
            this.dialog = false;
            this.localAnimal = null;
            this.$refs.form.resetValidation();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  }
</script>

<style scoped>

</style>
