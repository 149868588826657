<template>
  <v-app>
    <v-progress-linear
      color="primary"
      height="2"
      :indeterminate="true"
      v-if="loadingNav"
      class="mainLoader"
    ></v-progress-linear>
    <snackbar/>
    <loader-fullscreen/>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </v-app>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Getter,
  } from 'vuex-class';
  import Snackbar from '@/modules/common/snackbar/_components/Snackbar.vue';
  import LoaderFullscreen from '@/modules/common/loader/_components/LoaderFullscreen.vue';

  @Component({
    components: {
      Snackbar,
      LoaderFullscreen,
    },
  })
  export default class App extends Vue {
    @Getter('common/loader/loadingNav') public loadingNav!: boolean;
  }
</script>

<style lang="scss">
</style>
