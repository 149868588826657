<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-btn
      color="primary"
      slot="activator"
    >
      <v-icon>add</v-icon>
      Create Subscription
    </v-btn>
    <v-card v-if="subscription">
      <v-card-title class="primary">
        <span class="headline white--text">Create Subscription</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs6>
                <v-switch
                  v-model="subscription.deleted"
                  :label="`Deleted: ${subscription.deleted.toString()}`"
                ></v-switch>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="subscription.entry_date"
                      label="Date"
                      hint="Entry Date"
                      persistent-hint
                      prepend-icon="event"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="subscription.entry_date" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  number
                  v-model="subscription.var_sym"
                  label="Var Sym"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="subscription.status"
                  label="Status"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  :items="animals.filter((animal) => !animal.deleted)"
                  item-text="title"
                  item-value="id"
                  v-model="subscription.animalId"
                  label="Animal"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <small>*Required</small>
      </v-card-text>
      <v-card-actions class="mb-3">
        <v-spacer/>
        <v-btn
          class="mb-3"
          @click.native="create()"
          :loading="loading"
          :disabled="!valid"
          color="primary"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  @Component
  export default class DlgSubscriptionsCreate extends Vue {
    public $refs!: {
      form: any,
    };

    @Prop({type: Object, default: null}) private userId!: any;
    @Action('admin/subscriptions/create') private createSubscription!: any;
    @Action('admin/subscriptions/read') private readSubscriptions!: any;
    @Getter('common/rules/all') private rules!: any;
    @Getter('admin/animals/all') private animals!: any;

    private subscription: any = null;
    private dialog: boolean = false;
    private loading: boolean = false;
    private valid: boolean = true;

    private menu1: boolean = false;

    @Watch('dialog')
    private onDialogChanged(val: boolean) {
      if (val) {
        this.subscription = {
          deleted: false,
          entry_date: new Date().toISOString().substr(0, 10),
          var_sym: 0,
          status: 50,
        };
      }
    }

    private async create() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const success = await this.createSubscription({
          ...this.subscription,
          userSub: this.userId,
          entry_date: this.subscription.entry_date + ' 00:00:00',
        });

        if (success) {
          this.$emit('done');
          this.dialog = false;
          this.subscription = null;
          this.$refs.form.resetValidation();
        }

        this.loading = false;
      }
    }

  }
</script>

<style scoped>

</style>
