import { render, staticRenderFns } from "./DlgAnimalsDelete.vue?vue&type=template&id=7780acb5&scoped=true"
import script from "./DlgAnimalsDelete.vue?vue&type=script&lang=js"
export * from "./DlgAnimalsDelete.vue?vue&type=script&lang=js"
import style0 from "./DlgAnimalsDelete.vue?vue&type=style&index=0&id=7780acb5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7780acb5",
  null
  
)

export default component.exports