import animals from './animals';
import comments from './comments';
import posts from './posts';
import subscriptions from './subscriptions';
import likes from './likes';

export default {
  namespaced: true,
  modules: {
    animals,
    comments,
    posts,
    subscriptions,
    likes,
  },
};
