<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-btn
      slot="activator"
      fixed
      fab
      bottom
      right
      color="primary"
      style="border-radius: 100px !important;"
    >
      <v-icon>add</v-icon>
    </v-btn>
    <v-card>
      <v-card-title class="primary">
        <span class="headline white--text">Create new Post</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <div >
              <v-flex
                xs12
                sm8 offset-sm2
                md6 offset-md3
                lg4 offset-lg4
              >
                <media-selector v-model="postFile" />
              </v-flex>
              <v-select
                :items="filteredAnimals"
                :rules="[rules.required]"
                item-text="title"
                item-value="id"
                label="Animal *"
                v-model="selected"
              >
                <template v-slot:item="{ item }">
                  <v-chip style="background-color: white" class=".body-1">
                    <v-avatar left style="border: 0;">
                      <s3-image :itemKey="item.profileImage.replace('/full/', '/small/')" class="elevation-6"/>
                    </v-avatar>
                    {{ item.title }}
                  </v-chip>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip style="background-color: white" class=".body-1">
                    <v-avatar left>
                      <s3-image :itemKey="item.profileImage.replace('/full/', '/small/')" class="elevation-6"/>
                    </v-avatar>
                    {{ item.title }}
                  </v-chip>
                </template>
              </v-select>

              <v-flex
                xs12
              >
                <v-textarea
                  v-model="post.content"
                  :rules="[rules.required]"
                  label="Text *"
                ></v-textarea>
              </v-flex>
              <small>*Required</small>
              <v-flex xs12 class="text-xs-right">
                <v-btn
                  color="primary"
                  @click.native="save()"
                  :loading="loading"
                  :disabled="!valid||!postFile"
                >
                  Create Post
                </v-btn>
              </v-flex>
            </div>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Animal from '@/types/Animal';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {
  Action,
  Getter,
} from 'vuex-class';
import S3Image from '@/components/S3Image.vue';
import MediaSelector from "@/components/MediaSelector.vue";
@Component({
  components: {S3Image, MediaSelector},
})
export default class DlgPostsNew extends Vue {
  public $refs!: {
    form: any,
  };

  @Action('moderator/posts/create') private createPost!: any;
  @Action('moderator/posts/read') private readPosts!: () => Promise<void>;
  @Action('moderator/animals/read') private readAnimals!: () => Promise<void>;
  @Action('moderator/posts/uploadImage') private uploadImage!: any;
  @Action('moderator/posts/uploadVideo') private uploadVideo!: any;
  @Getter('common/rules/all') public rules!: any;
  @Getter('moderator/animals/all') public animals!: Animal[];

  public post: any = {};
  public postFile: any = null;
  public dialog: boolean = false;
  public loading: boolean = false;
  public valid: boolean = true;
  public selected: string = '';

  @Watch('dialog')
  private async changedDialogValue(val: boolean) {
    if (val) {
      this.loading = true;
      await this.readAnimals();
      this.loading = false;
    }
  }

  get filteredAnimals() {
    return this.animals.filter(animal => !animal.deleted);
  }

  public async save() {
    if (this.$refs.form.validate() && this.selected) {
      this.loading = true;

      this.post.animalId = this.selected;

      if (this.postFile.type.startsWith('image')){
        this.post.images = [await this.uploadImage({animalId: this.post.animalId, image: this.postFile})];
      } else {
        this.post.video = await this.uploadVideo({animalId: this.post.animalId, video: this.postFile});
      }

      this.createPost(this.post)
        .then((success: any) => {
          if (success) {
            return this.readPosts();
          }
          throw new Error();
        })
        .then(() => {
          this.loading = false;
          this.dialog = false;
          this.post = {};
          this.$refs.form.resetValidation();
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.animal-avatar {
  cursor: pointer;
  transition: 0.3s all;
}

.dark {
  -webkit-filter: grayscale(100%) brightness(70%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(70%);
}

.dark:hover {
  -webkit-filter: none; /* Safari 6.0 - 9.0 */
  filter: none;
}

</style>
