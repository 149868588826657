import {Auth} from 'aws-amplify';

import GuestBase from '@/views/Guest/Layout.vue';

import GuestHome from '@/views/Guest/Home.vue';
import About from '@/views/Guest/About.vue';
import Impressum from '@/views/Guest/Impressum.vue';

import SignIn from '@/views/Guest/Auth/SignIn.vue';
import SignUp from '@/views/Guest/Auth/SignUp.vue';
import ConfirmSignUp from '@/views/Guest/Auth/ConfirmSignUp.vue';
import CompleteNewPassword from '@/views/Guest/Auth/CompleteNewPassword.vue';
// import ResendSignUp from '@/views/Guest/Auth/ResendSignUp.vue';
import NewPasswordRequired from '@/views/Guest/Auth/NewPasswordRequired.vue';
import ForgotPasswordSubmit from '@/views/Guest/Auth/ForgotPasswordSubmit.vue';
import ForgotPassword from '@/views/Guest/Auth/ForgotPassword.vue';
import Animals from '@/views/Guest/Animals/List.vue';
import Animal from '@/views/Guest/Animals/Details.vue';


async function loggedOut(to: any, from: any, next: any) {
  try {
    await Auth.currentAuthenticatedUser();
    next({name: 'UserBase'});
  } catch (e) {
    next();
  }
}

export default [
  {
    path: '/',
    name: 'GuestBase',
    component: GuestBase,
    redirect: {name: 'SignIn'},
    children: [
      {
        path: '',
        name: 'GuestHome',
        component: GuestHome,
      },
      {
        path: 'signIn',
        name: 'SignIn',
        component: SignIn,
        beforeEnter: loggedOut,
      },
      {
        path: 'signUp',
        name: 'SignUp',
        component: SignUp,
        beforeEnter: loggedOut,
      },
      {
        path: 'confirmSignUp',
        name: 'ConfirmSignUp',
        component: ConfirmSignUp,
        beforeEnter: loggedOut,
      },
      {
        path: 'completeNewPassword',
        name: 'CompleteNewPassword',
        component: CompleteNewPassword,
        beforeEnter: loggedOut,
      },
      {
        path: 'newPasswordRequired',
        name: 'NewPasswordRequired',
        component: NewPasswordRequired,
        beforeEnter: loggedOut,
      },
      // {
      //     path: 'resendSignUp',
      //     name: 'ResendSignUp',
      //     component: ResendSignUp,
      // },
      {
        path: 'forgotPasswordSubmit',
        name: 'ForgotPasswordSubmit',
        component: ForgotPasswordSubmit,
        beforeEnter: loggedOut,
      },
      {
        path: 'forgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        beforeEnter: loggedOut,
      },
      {
        path: 'animals',
        name: 'GuestAnimals',
        component: Animals,
        beforeEnter: loggedOut,
      },
      {
        path: 'animals/:id',
        name: 'GuestAnimal',
        component: Animal,
        beforeEnter: loggedOut,
        props: true,
      },
    ],
  },
];
