<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 class="text-xs-center" v-if="!loading && posts.length === 0">
        There are no posts.
      </v-flex>
      <v-flex
        xs12
        sm8 offset-sm2
        lg6 offset-lg3
        class="pa-3"
        v-for="(post, index) in posts"
        v-if="index <= limit"
        :key="post.id"
      >
        <post-card :post="post" :animal="getAnimal(post.animalId)" userType="admin">
          <template v-slot:deletePost>
            <dlg-posts-delete :post="post"></dlg-posts-delete>
          </template>
        </post-card>
      </v-flex>
      <v-flex xs12 text-xs-center>
        <infinite-loading v-if="!loading" @infinite="infiniteHandler">
          <span slot="no-more"></span>
          <span slot="no-results"></span>
        </infinite-loading>
      </v-flex>
    </v-layout>
    <dlg-posts-new/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter,} from 'vuex-class';
import DlgPostsNew from '@/modules/admin/posts/_components/DlgPostsNew.vue';
import DlgPostsEdit from '@/modules/admin/posts/_components/DlgPostsEdit.vue';
import S3Image from '@/components/S3Image.vue';
import DlgPostsDelete from '@/modules/admin/posts/_components/DlgPostsDelete.vue';
import PostCommentField from '@/modules/admin/comments/_components/PostCommentField.vue';
import DeleteCommentField from '@/modules/admin/comments/_components/DeleteCommentField.vue';
import PostLikeField from '@/modules/admin/likes/_components/PostLikeField.vue';
import DlgPostsNewMobile from '@/modules/admin/posts/_components/DlgPostsNewMobile.vue';
import Loader from '@/components/Loader.vue';
import InfiniteLoading from 'vue-infinite-loading';
import S3Video from '@/components/S3Video.vue';
import PostCard from "@/components/Global/PostCard.vue";

@Component({
  components: {
    PostCard,
    S3Video,
    Loader,
    DlgPostsNewMobile,
    PostLikeField,
    DeleteCommentField,
    PostCommentField,
    DlgPostsDelete,
    S3Image,
    DlgPostsEdit,
    DlgPostsNew,
    InfiniteLoading,
  },
})
export default class PostsList extends Vue {
  public loading: boolean = false;
  public limit: any = 10;
  @Prop({type: String, default: ''}) private postId!: string;

  @Action('admin/posts/read') private readPosts!: () => Promise<boolean>;
  @Action('admin/posts/infiniteRead') private infiniteReadPosts!: () => Promise<boolean>;
  @Action('admin/animals/read') private readAnimals!: () => Promise<boolean>;

  @Getter('admin/posts/all') public posts!: any[];
  @Getter('admin/animals/all') private animals!: any[];
  @Getter('admin/posts/isAllLoaded') private isAllLoaded!: boolean;


  private mounted() {
    this.init();
  }

  private init() {
    this.loading = true;

    this.readAnimals()
      .then(() => {
        return this.readPosts();
      })
      .then((success) => {
        if (success) {
          this.loading = false;
        } else {
          throw new Error('Some Error, handle it');
        }
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  public async infiniteHandler($state) {
    if (this.posts.length > this.limit) {
      this.limit += 10;
      return $state.loaded();
    }
    if (this.isAllLoaded) {
      return $state.complete();
    }
    await this.infiniteReadPosts();
    return $state.loaded();
  }


  public getAnimal(id: string) {
    for (const animal in this.animals) {
      if (this.animals[animal].id === id) {
        return this.animals[animal];
      }
    }
    return null;
  }


}
</script>

<style>
.v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: 0 !important;
}

</style>
