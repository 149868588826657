<template>
  <span>
    {{ post.likes > 0 ? post.likes : '' }}
    <v-btn @click="like" v-if="post.liked" flat icon :loading="loading" color="red">
      <v-icon dark>favorite</v-icon>
    </v-btn>
    <v-btn @click="like" v-else flat icon :loading="loading">
      <v-icon dark>favorite_border</v-icon>
    </v-btn>
  </span>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action} from 'vuex-class';

  @Component
  export default class PostCommentField extends Vue {
    @Prop({type: Object, default: {}}) private post!: any;

    private commentText: string = '';
    private loading: boolean = false;

    @Action('admin/likes/create') private createLike!: (post: any) => Promise<boolean>;
    @Action('admin/likes/del') private removeLike!: (post: any) => Promise<boolean>;
    @Action('admin/posts/readOne') private readPost!: ({ id }) => Promise<boolean>;

    private async like() {
      this.loading = true;
      if (!this.post.liked) {
        await this.createLike(this.post);
      } else {
        await this.removeLike(this.post);
      }
      await this.readPost(this.post);
      this.$emit('update', this.post);
      this.loading = false;
    }

  }
</script>

<style scoped>

</style>
