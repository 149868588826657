import {Auth} from 'aws-amplify';

import i18n from '@/i18n';

export const signIn = async ({commit, dispatch}: any, {username, password}: any) => {
  try {
    commit('setUsername', username);
    const res = await Auth.signIn(username, password);
    if (res.challengeName) {
      throw ({
        code: 'NEW_PASSWORD_REQUIRED',
        message: i18n.t('auth.newPassword.alert'),
      });
    }
    return true;
  } catch (err: any) {
    dispatch('common/snackbar/warning', err, {root: true});
    if (err && err.code) {
      commit('setChallenge', err.code);
    }
    return false;
  }
};
// @ts-ignore
export const completeNewPassword = async ({dispatch}, {username, password, newPassword}) => {
  try {
    username = username.toLowerCase();
    const user = await Auth.signIn(username, password);
    await Auth.completeNewPassword(user, newPassword, {});
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const signUp = async ({commit, dispatch}, {username, password}) => {
  try {
    username = username.toLowerCase();
    commit('setUsername', username);
    commit('setPassword', password);
    await Auth.signUp({username, password, attributes: {email: username}});
    return true;
  } catch (err) {
    // TODO: save error code somewhere in state
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const confirmSignUp = async ({dispatch}, {username, code}) => {
  try {
    username = username.toLowerCase();
    await Auth.confirmSignUp(username, code);
    dispatch('common/snackbar/success', 'User confirmed!', {root: true});
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const resendSignUp = async ({dispatch}, {username}) => {
  try {
    username = username.toLowerCase();
    await Auth.resendSignUp(username);
    dispatch('common/snackbar/success', i18n.t('auth.resendCodeSuccess'), {root: true});
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const signOut = async ({dispatch}) => {
  try {
    dispatch('common/ui/setSidebar', false, {root: true});
    await Auth.signOut();
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const changePassword = async ({dispatch}, {oldPassword, newPassword}) => {
  try {
    await Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, oldPassword, newPassword));
    dispatch('common/snackbar/success', i18n.t('auth.changePasswordSuccess'), {root: true});
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const forgotPassword = async ({commit, dispatch}, {username}) => {
  try {
    username = username.toLowerCase();
    commit('setUsername', username);
    await Auth.forgotPassword(username);
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const forgotPasswordSubmit = async ({dispatch}, {username, code, newPassword}) => {
  try {
    username = username.toLowerCase();
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch('common/snackbar/success', i18n.t('auth.changePasswordSuccess'), {root: true});
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
};
// @ts-ignore
export const loadUser = async ({dispatch, commit}) => {
  try {
    await Auth.currentSession();
    const user = await Auth.currentUserInfo();
    commit('setPreferred_Username', user.attributes?.preferred_username || null);
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    dispatch('common/loader/removeLoader', {}, {root: true});
    return false;
  }
};

export const updatePreferred_Username = async ({dispatch, commit}, preferred_username: string) => {
  try {
    await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {preferred_username: preferred_username});
    commit('setPreferred_Username', preferred_username);
    dispatch('common/snackbar/success', i18n.t('auth.updatedUsername'), {root: true});
    return true;
  } catch (err) {
    dispatch('common/snackbar/warning', err, {root: true});
    return false;
  }
}
