<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl4>
        <v-form ref="form" @submit="Confirm" lazy-validation>
          <v-card class="elevation-12">
            <v-card-title primary-title class="primary">
              <div class="headline white--text">{{$t('auth.confirmSignUp.header')}}</div>
            </v-card-title>
            <v-card-text>
              <v-text-field
                autocomplete="email"
                prepend-icon="mail"
                name="email"
                :label="$t('auth.email')"
                :rules="[ rules.required, rules.email ]"
                :disabled="loading"
                v-model="user.username"
                type="text">
              </v-text-field>
              <v-text-field
                prepend-icon="vpn_key"
                name="code"
                :label="$t('auth.confirmSignUp.code')"
                :rules="[ rules.required, rules.code ]"
                :disabled="loading"
                v-model="user.code"
                type="text">
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                @click="Resend"
                :loading="loading"
                type="submit"
                :disabled="!this.user.username">
                {{$t('auth.confirmSignUp.resend')}}
              </v-btn>
              <v-btn
                color="primary"
                @click="Confirm"
                :loading="loading"
                type="submit">
                {{$t('auth.confirm')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  import {mapActions} from 'vuex';

  @Component
  export default class SignUp extends Vue {
    public $refs!: {
      form: any,
    };

    private user: any = {};
    private loading: boolean = false;
    @Getter('common/rules/all') private rules!: any;
    @Getter('guest/auth/username') private username!: any;
    @Getter('guest/auth/password') private password!: any;

    @Action('guest/auth/signIn') private signIn!: any;
    @Action('guest/auth/resendSignUp') private resendSignUp!: any;
    @Action('guest/auth/confirmSignUp') private confirmSignUp!: any;

    private mounted() {
      if (this.username) {
        this.user.username = this.username;
        this.user.password = this.password;
      }
    }

    private async Confirm(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;
        const res = await this.confirmSignUp(this.user);
        if (res) {
          if (this.user.password && await this.signIn(this.user)) {
            this.$router.push({name: 'UserBase'});
          } else {
            this.$router.push({name: 'SignIn'});
          }
        }
        this.loading = false;
      }
    }

    private async Resend(e) {
      e.preventDefault();
      this.loading = true;
      await this.resendSignUp(this.user);
      this.loading = false;
    }
  }
</script>

<style scoped>

</style>
