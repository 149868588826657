<template>
  <v-container fluid grid-list-sm pt-5 v-if="!loading && animal">
    <v-layout row wrap>
      <v-flex xs12 sm10 offset-sm1 md8 offset-md2 xl6 offset-xl3 :key="animal.id">
        <v-layout row wrap>
          <v-flex xs12 md4 class="text-xs-center">
            <s3-image :itemKey="animal.profileImage.replace('/full/', '/profile/')"
                      class="profile-image elevation-4"/>
          </v-flex>
          <v-flex xs12 md8>
            <h1 class="display-1 primary--text pb-2">{{ animal.title }}, {{ animal.age }}</h1>
            <b v-if="animal.deleted" class=".body-2">Hurá, som na ceste do novej rodiny!</b>
            <animal-subscribe :animal="animal" v-if="!animal.deleted"></animal-subscribe>
            <p class="mt-3 subheading">
              {{ animal.shortDescription }}
            </p>
            <p class="body-1">
              {{ animal.longDescription }}
            </p>
          </v-flex>

          <v-flex v-for="post in animal.posts" :key="post.id"
                  lg4
                  md6
                  xs12
                  d-flex
                  class="pa-2"
          >
            <post-display :post="post"></post-display>
          </v-flex>
          <v-flex
            v-for="post in animal.postsPreview" :key="post.id + post.animalId"
            lg4
            md6
            xs12
            d-flex
            class="pa-2"
          >
            <post-preview :post="post"></post-preview>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import PostDisplay from '@/components/Global/PostDisplay.vue';
import PostPreview from '@/components/Global/PostPreview.vue';
import S3Image from '@/components/S3Image.vue';

import AnimalSubscribe from '@/modules/user/subscriptions/_components/AnimalSubscribe.vue';
import Animal from '@/types/Animal';

import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';

@Component({
  components: {
    S3Image,
    PostDisplay,
    PostPreview,
    AnimalSubscribe,
  },
})
export default class List extends Vue {
  public loading: boolean = true;

  @Prop({type: String, default: ''}) private id!: string;

  @Action('user/animals/readOne') private readAnimal!: any;
  @Getter('user/animals/one') public animal!: Animal;

  private async created() {
    this.loading = true;
    await this.readAnimal({id: this.id});
    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
.profile-image {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.img-blur {
  filter: blur(4px);
}
</style>
