<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-tooltip bottom slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <v-icon medium color="error">delete</v-icon>
        </v-btn>
      </template>
      <span>Delete</span>
    </v-tooltip>
    <v-card>
      <v-card-title class="primary" >
        <span class="headline white--text">Remove Post</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="my-3">
        Do you really want to delete this post?
      </v-card-text>

      <v-card-actions class="mb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click.native="del()"
          class="mb-3"
          :loading="loading"
        >
          Delete
        </v-btn>

        <v-btn
          @click.native="dialog = false"
          class="mb-3"
          color="primary"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action} from 'vuex-class';

  @Component
  export default class DlgPostsDelete extends Vue {
    @Prop({type: Object, default: null}) private post!: any;

    private loading: boolean = false;
    private dialog: boolean = false;

    @Action('moderator/posts/del') private postDel!: any;
    @Action('moderator/posts/read') private postRead!: any;

    private del() {
      this.loading = true;
      this.postDel(this.post).then((res) => {
        this.loading = false;
        if (res) {
          this.postRead();
          this.dialog = false;
        }
      });
    }
  }
  // // import { VueEditor } from 'vue2-editor';
  // import { mapActions } from 'vuex';
  //
  //
  // export default {
  //   name: 'DlgMemberDelete',
  //   components: {
  //     // VueEditor,
  //   },
  //   data() {
  //     return {
  //       dialog: false,
  //       loading: false,
  //     };
  //   },
  //   props: ['member'],
  //   methods: {
  //     ...mapActions({
  //       memberDel: 'members/del',
  //       memberRead: 'members/read',
  //     }),
  //     del() {
  //       this.loading = true;
  //       this.memberDel(this.member).then((res) => {
  //         this.loading = false;
  //         if (res) {
  //           this.memberRead();
  //           this.dialog = false;
  //         }
  //       });
  //     },
  //   },
  // };
</script>

<style scoped>

</style>
