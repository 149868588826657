<template>
  <section id="impress" class="py-5">
    <v-container grid-list-xl>
      <v-layout row wrap justify-center >
        <v-flex xs12 class="text-xs-center">
          <div class="display-1 v-heading mb-3 font-weight-light">CELOSLOVENSKÉ CENTRUM SLOBODY ZVIERAT</div>
        </v-flex>
        <v-flex xs12 sm4>
          <v-card class="elevation-0 transparent">
            <v-card-text class="body-1">
              <b class="title">Útulok Slobody zvierat</b><br/>
              Pod Brehmi 1/a, 841 03, Bratislava IV – Polianky<br/>
              Tel. / 02/16187<br/>
              E-mail: <a href="mailto:milacik@slobodazvierat.sk" class="body-2">milacik@slobodazvierat.sk</a><br/>
              <br/>
              <b class="title">MHD</b><br/>
              Do útulku na Polianky sa dostanete autobusmi:<br/>
              <br/>
              č. 35 – zastávka Polianky<br/>
              č. 21, 30, 83, 84 – zastávka Mokrohájska<br/>
              č. 22, 34, 83 – zastávka Huščavova<br/>
              Viac na <a href="http://www.imhd.sk/ba/" target="_blank" class="body-2">www.imhd.sk/ba/</a><br/>
              <br/>
              <b class="title">Korešpondenčná adresa:</b><br/>
              SLOBODA ZVIERAT<br/>
              Pod Brehmi 1/a, 841 03, Bratislava IV – Polianky<br/>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm4 offset-sm1>
          <v-card class="elevation-0 transparent">
            <v-card-text class="body-1">
              Právna forma: občianske združenie<br/>
              IČO: 319 482 01<br/>
              IBAN: SK4065000000000002023636<br/>
              SWIFT: POBNSKBA<br/>
              <br/>
              <b class="title">Číslo účtu pre finančnú podporu zo zahraničia:</b><br/>
              IBAN: SK 8965 00000 00000 53027100<br/>
              BIC (SWIFT): POBNSKBA<br/>
              <br/>
              Poštová banka<br/>
              P.O.Box 149, 814 99 Bratislava<br/>
              <br/>
              <b><a href="/SZ_SK_AGB_online_adoption.pdf" target="_blank" class="body-2">Všeobecné podmienky projektu</a></b><br/>
              <b><a href="http://slobodazvierat.sk/prehlasenie-o-ochrane-osobnych-udajov/" target="_blank" class="body-2">Ochrana osobných údajov</a></b><br/>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
  export default {
    name: 'GuestInfo',
  };
</script>

<style scoped>

</style>
