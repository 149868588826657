<template>
  <v-dialog
    v-model="loading"
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        {{$t('common.loading')}}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoaderFullscreen',
  computed: {
    ...mapGetters({
      loading: 'common/loader/loading',
    }),
  },
};
</script>

<style scoped>
  .fullscreen {
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
  }

  .loader {
    z-index: 99999;
  }

</style>
