<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-tooltip bottom slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon flat small style="margin: 0;" :loading="loading" slot="activator">
          <v-icon color="error">delete</v-icon>
        </v-btn>
      </template>
      <span>Delete</span>
    </v-tooltip>

    <v-card>
      <v-card-title class="primary" >
        <span class="headline white--text">Remove Comment</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>


      <v-card-text class="my-3">
        Do you really want to remove the comment?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click="deleteComment()"
          class="mb-3"
        >
          Delete
        </v-btn>

        <v-btn
          @click="dialog = false"
          class="mb-3"
          color="primary"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action} from 'vuex-class';

  @Component
  export default class DeleteCommentField extends Vue {
    @Prop({type: Object, default: {}}) private comment!: any;

    private loading: boolean = false;
    private dialog: boolean = false;

    @Action('moderator/comments/del') private storeDeleteComment!: (comment: any) => Promise<boolean>;
    @Action('moderator/posts/readOne') private readPost!: ({id}) => Promise<boolean>;

    private deleteComment() {
      this.loading = true;
      this.dialog = false;
      this.storeDeleteComment(this.comment)
        .then((success) => {
          if (!success) {
            throw new Error('Error while creating comment');
          }
          return this.readPost({id: this.comment.postId});
        })
        .then((post) => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
</script>

<style scoped>

</style>
