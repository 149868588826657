<template>
  <div>
    <v-container>
      <card
        color="primary"
        title="Users"
        v-model="search"
      >
        <v-select
          v-model="filter"
          :items="items"
          item-text="name"
          item-value="val"
          label="Filter"
        ></v-select>
        <v-data-table
          :headers="headers"
          :items="filteredUsers"
          :loading="loading"
          :search="search"
          :rows-per-page-items="[10, 5]"
        >
          <template slot="items" slot-scope="props">
            <td><b style="font-size: 14px">{{ props.item.given_name }}</b></td>
            <td><b style="font-size: 14px">{{ props.item.family_name }}</b></td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.subscriptions }}</td>
            <td width="156">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn :to="{ name: 'AdminUser', params: { id: props.item.sub }}" flat icon color="primary" v-on="on">
                    <v-icon>search</v-icon>
                  </v-btn>
                </template>
                <span>Show User</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </card>
    </v-container>

  </div>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {Action, Getter} from 'vuex-class';
  import S3Image from '@/components/S3Image.vue';
  import Card from '@/components/material/Card.vue';

  @Component({
    components: {
      Card,
      S3Image,
    },
    filters: {
      statusIcon(val) {
        switch (val) {
          case 'CONFIRMED':
            return 'check';
          case 'FORCE_CHANGE_PASSWORD':
            return 'help_outline';
          default:
            return 'error_outline';
        }
      },
      date(val) {
        const date = new Date(val);
        return date.toLocaleDateString('de-DE');
      },
    },
  })
  export default class UsersList extends Vue {
    public loading: boolean = false;

    @Action('admin/users/read') private readUsers!: () => Promise<boolean>;
    @Getter('admin/users/all') private users!: any[];

    public headers: any = [
      {
        text: 'Forename',
        align: 'left',
        value: 'given_name',
      },
      {
        text: 'Surname',
        align: 'left',
        value: 'family_name',
      },
      {
        text: 'Email',
        align: 'left',
        sortable: true,
        value: 'email',
      },
      {
        text: '# Subscriptions',
        align: 'left',
        sortable: true,
        value: 'subscriptions',
      },
      {
        text: '',
        align: 'left',
        sortable: false,
        value: '',
      },
    ];

    public filter: number = 1;
    public search: string = '';
    public items: any = [
      {name: 'All Users', val: 1},
      {name: 'Users with active subscription', val: 2},
      {name: 'Users without subscriptions', val: 3},
    ];

    get filteredUsers() {
      if (this.filter === 1) {
        return this.users;
      }

      if (this.filter === 2) {
        return this.users.filter((user) => user.subscriptions > 0);
      }

      if (this.filter === 3) {
        return this.users.filter((user) => user.subscriptions === 0);
      }
    }

    private mounted() {
      this.loading = true;

      this.readUsers()
        .then((success) => {
          if (success) {
            this.loading = false;
          } else {
            throw new Error('Some Error, handle it');
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    }
  }
</script>

<style scoped>

</style>
