<template>
  <section
    id="screenshots1"
    class="hide-overflow"
  >
    <v-layout>
      <v-flex
        hidden-sm-and-down
        md6
      >
        <v-img
          src="/img/dog2.jpg"
        />
      </v-flex>

      <v-flex
        xs12
        md6
        align-content-space-between
        layout
        pa-5
        wrap
      >
        <v-layout
          align-center
          justify-center
          fill-height
        >
          <v-flex
            xs10
            md6
            class="body-1"
          >
            <h2 class="display-1 v-heading mb-4 font-weight-light text-uppercase">Ako to funguje</h2>
            <p>
              Takto jednoducho to funguje:
            </p>
            <p>
              Vyber si svojho miláčika.
              Stiahni si našu aplikáciu na mobil.
              <b>Cez aplikáciu</b> budeš dostávať informácie o svojom miláčikovi.
              Nezabudni sa pochváliť svojim miláčikom aj známym :o)
            </p>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
  </section>
</template>

<script>
  export default {
    name: 'GuestScreenshots',
  };
</script>

<style scoped>

</style>
