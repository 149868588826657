import animals from './animals';
import comments from './comments';
import likes from './likes';
import moderators from './moderators';
import posts from './posts';
import users from './users';
import subscriptions from './subscriptions';

export default {
  namespaced: true,
  modules: {
    animals,
    comments,
    likes,
    moderators,
    posts,
    users,
    subscriptions,
  },
};
