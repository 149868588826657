import * as API from '@/modules/user/subscriptions/_api';

import i18n from '@/i18n';

// @ts-ignore
export const read = async ({commit, dispatch}) => {
  try {
    const all = await API.read();
    commit('setAll', all);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', i18n.t('user.error'), {root: true});
    return false;
  }
};

// @ts-ignore
export const del = async ({commit, dispatch}, subscription) => {
  try {
    // btoa(JSON.stringify(data))
    const res = await API.del(subscription);
    // console.log(res);
    return res;
  } catch (ex) {
    dispatch('common/snackbar/error', i18n.t('user.error'), {root: true});
    return false;
  }
};

// @ts-ignore
export const create = async ({commit, dispatch}, data) => {
  try {
    return await API.create(data);
  } catch (ex) {
    dispatch('common/snackbar/error', i18n.t('user.error'), {root: true});
    return false;
  }
};
