import Vue from 'vue';

Vue.filter('timeAgo', (value: string) => {
  if (!value) {
    return '<n/a>';
  }
  const date = new Date(value);
  // @ts-ignore
  const seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);
  // seconds ago?
  //
  // return `${date.getDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()} - `
  //   + `${date.getUTCHours()}:${date.getUTCMinutes()}`;


  if (interval >= 1) {
    return `${interval} year${interval > 1 ? 's' : ''} ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return `${interval} month${interval > 1 ? 's' : ''} ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval} day${interval > 1 ? 's' : ''} ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} hour${interval > 1 ? 's' : ''} ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} minute${interval > 1 ? 's' : ''} ago`;
  }
  return `${Math.floor(seconds)} second${interval === 1 ? '' : 's'} ago`;
});
