<template>
  <div class="lds-dual-ring"></div>
</template>

<script>
  import {Vue, Component} from 'vue-property-decorator';

  @Component
  export default class Loader extends Vue {
  }
</script>

<style scoped>
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #595959;
    border-color: #383838 transparent #383838 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
