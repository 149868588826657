<template>
  <section id="welcome" class="mb-5">
    <v-parallax src="img/pet-423398_1920.jpg" class="parrallax" height="900">
      <v-layout
        align-center
        row wrap
        justify-center
      >
        <!--        Hide in SM, -->
        <v-flex sm12 md4 lg6 xl5 offset-md1 hidden hidden-sm-and-down>
        </v-flex>
        <v-flex xs12 sm8 md6 lg4 xl3 class="text-xs-center">
          <v-layout align-center justify-center row wrap>
            <v-flex sm12 class="text-xs-center text-sm-center text-md-right pb-5">
              <h1 class="display-3 font-weight- mb-3 ">Miláčik na diaľku</h1>
              <h3 class="subheading">Adopcia na diaľku</h3>
            </v-flex>
            <v-flex xs12>
              <v-btn
                :to="{ name: 'GuestAnimals' }"
                color="primary"
                style="box-shadow: none !important;"
              >
                {{$t('auth.getBack.button')}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-parallax>
  </section>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import ICognitoUserData from '@/types/cognitoUserData';
  import { Action, Getter } from 'vuex-class';
  import { Auth } from 'aws-amplify';

  @Component
  export default class GuestHero extends Vue {
    public $refs!: {
      form: any,
    };

    private loading: boolean = false;
    private isUserLoggedIn: boolean = false;
    private user: any = {
      username: null,
      password: null,
    };

    @Getter('guest/auth/username') private username!: string;
    @Action('guest/auth/signIn') private storeSignIn!: (user: ICognitoUserData) => Promise<any>;
    @Getter('guest/auth/challenge') private challenge!: any;
    @Getter('common/rules/all') private rules!: any[];

    public mounted() {
      if (this.username) {
        this.user.username = this.username;
      }
      Auth.currentAuthenticatedUser().then((user) => {
        this.isUserLoggedIn = true;
        this.user = user;
      }).catch(() => {
        this.isUserLoggedIn = false;
      });
    }

    private async signIn(e: Event) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;

        // TODO: Check if true or false is returned. If it is true, login was successful.
        // TODO: If it is false, login was unsuccessful and there might be a challenge
        const success = await this.storeSignIn(this.user);
        this.loading = false;

        if (success) {
          this.$router.push({name: 'UserBase'});
        } else {
          switch (this.challenge) {
            case 'UserNotConfirmedException':
              this.$router.push({name: 'ConfirmSignUp'});
              break;
            case 'PasswordResetRequiredException':
              this.$router.push({name: 'CompleteNewPassword'});
              break;
            case 'RESET_REQUIRED':
              this.$router.push({name: 'ForgotPasswordSubmit'});
              break;
            case 'NEW_PASSWORD_REQUIRED':
              this.$router.push({name: 'NewPasswordRequired'});
              break;
          }
        }
        // .then((res: any) => {
        //   this.loading = false;
        //   if (res && res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //     this.$router.push({ name: 'CompleteNewPassword' });
        //   } else if (res && res.code === 'RESET_REQUIRED') {
        //     this.$router.push({ name: 'ForgotPasswordSubmit' });
        //   } else {
        //     this.$router.push({ name: 'UserBase' });
        //   }
        // });
      }
    }
  }
</script>

<style scoped>

</style>
