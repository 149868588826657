<template>
  <v-data-table
    :headers="headers"
    :items="subscriptions"
    class="elevation-1"
  >
    <template v-slot:items="props">
      <td class="text-xs-right">{{ props.item.user.given_name }} {{ props.item.user.family_name }}</td>
      <td class="text-xs-right">{{ props.item.user.email }}</td>
      <td class="text-xs-right">{{ props.item.entry_date }}</td>
      <td class="text-xs-right">{{ props.item.amount }}</td>
      <td class="text-xs-right">{{ props.item.var_sym }}</td>
    </template>
  </v-data-table>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';

  @Component
  export default class AnimalSubscriptionsTable extends Vue {
    @Prop({type: Array, default: null}) public subscriptions!: any;

    public headers: any = [
      {text: 'Name', value: 'user.given_name'},
      {text: 'Email', value: 'user.email'},
      {text: 'Entry Date', value: 'entry_date'},
      {text: 'Amount', value: 'amount'},
      {text: 'Var Sym', value: 'var_sym'},
    ];
  }
</script>

<style scoped>

</style>
