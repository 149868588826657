
// @ts-ignore
export const setAll = (state, all) => {
  state.all = all;
};

// @ts-ignore
export const setOne = (state, one) => {
  state.one = one;
};
