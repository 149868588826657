// @ts-ignore
export const all = (state, getters, rootState) => {
  // match animals and users?
  const mappedUsers = {};
  const mappedAnimals = {};

  rootState.admin.users.all.forEach((user) => {
    mappedUsers[user.id] = user.email;
  });
  rootState.admin.animals.all.forEach((animal) => {
    mappedAnimals[animal.id] = animal.title;
  });

  return state.all.map((el) => {
    el.animal = mappedAnimals[el.animalId];
    el.user = mappedUsers[el.userSub];

    const date1 = new Date(el.createdAt);
    const date2 = new Date(el.entry_date);
    el.daysSince = Math.max(0, Math.floor((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)));

    return el;
  });
};
