<template>
  <v-card style="border-radius: 10px">
    <v-card-actions>
      <v-flex style="display: flex; justify-content: space-between; flex-direction: row; align-items: center">
        <v-list-tile @click="goToAnimal()" style="cursor: pointer">
          <v-list-tile-avatar color="grey darken-3">
            <s3-image :itemKey="getAnimalProfileImage()" v-if="animal"
              class="elevation-6 profile-pic" />
            <v-img v-else :src="getAnimalProfileImage()" class="elevation-6 profile-pic"></v-img>
          </v-list-tile-avatar>
          <v-list-tile-content class="link headline primary--text" style="font-size: 18px">{{ animal.title }}
          </v-list-tile-content>
        </v-list-tile>
        <div>
          <slot name="deletePost"></slot>
        </div>
      </v-flex>
    </v-card-actions>

    <s3-image v-if="post.images" :itemKey="post.images[0].replace('/full/', '/post/')" :border="true" />
    <s3-video v-if="post.video" :itemKey="post.video" :border="true" />

    <v-card-text style="display: flex; flex-direction: column">
      <post-like-field :post="post" :userType="userType"></post-like-field>
      <div v-html="getPostText(post.content)" class="body-1"></div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-layout row wrap class="mb-3">
        <template v-for="comment in post.comments">
          <v-flex xs12 style="display: flex; justify-content: space-between">
            <span v-html="getComment(comment.username, comment.commentText)"></span>
            <delete-comment-field :comment="comment" :userType="userType"
              v-if="userType !== 'user'"></delete-comment-field>
          </v-flex>
          <v-flex xs12 class="mb-1" style="font-size: 14px; color: grey">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" class="caption">{{ comment.createdAt | timeAgo }}</span>
              </template>
              <span class="caption">{{ comment.createdAt | stringToDate }}</span>
            </v-tooltip>
          </v-flex>
        </template>
      </v-layout>
      <post-comment-field :post="post" :userType="userType"></post-comment-field>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import S3Image from '@/components/S3Image.vue';
import S3Video from '@/components/S3Video.vue';
import DeleteCommentField from '@/modules/admin/comments/_components/DeleteCommentField.vue';
import PostCommentField from '@/modules/user/comments/_components/PostCommentField.vue';
import PostLikeField from '@/modules/user/likes/_components/PostLikeField.vue';
import Animal from '@/types/Animal';
import Post from '@/types/Post';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
   components: {
     S3Image,
     PostCommentField,
     PostLikeField,
     S3Video,
     DeleteCommentField,
   },
 })
export default class PostCard extends Vue {
  @Prop({type: String, default: 'user'}) public userType!: 'user' | 'admin' | 'moderator';
  @Prop({default: {id: '', video: '', images: []}}) public post!: Post;
  @Prop({type: Object, default: null}) public animal!: Animal;

  public getPostText(description: string) {
    return description ? description.replace(/\n/gm, '</br>') : '';
  }

  public goToAnimal() {
    if (this.animal) {
      if (this.userType === 'admin') {
        this.$router.push({name: 'AdminAnimal', params: {id: this.animal.id}});
      } else if (this.userType === 'user') {
        this.$router.push({name: 'UserAnimal', params: {id: this.animal.id}});
      } else {
        this.$router.push({name: 'ModeratorAnimal', params: {id: this.animal.id}});
      }
    }
  }

  public getAnimalProfileImage() {
    if (!this.animal || !this.animal.profileImage) {
      return 'img/animal/removed.png';
    } else {
      return this.animal.profileImage.replace('/full/', '/tiny/');
    }
  }

  public getComment(username: string, comment: string) {
    if (username) {
      return `<span><b class="body-2" style="font-size: 16px">${username.trim()}:&nbsp;</b><span style="white-space: pre-line" class="body-1">${comment}</span></span>`;

    } else {
      return `<span style="white-space: pre-line" class="body-1">${comment}</span>`;
    }
  }
}
</script>
