<template>
  <v-content style="padding-top: 64px">
    <guest-toolbar/>
    <guest-drawer/>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </v-content>
</template>

<script>
import GuestToolbar from '@/components/Guest/GuestToolbar';
import GuestDrawer from '../../components/Guest/GuestDrawer';
export default {
  name: 'Layout',
  components: {GuestDrawer, GuestToolbar },
};
</script>

<style >
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s ease;
  }
  .fade-enter, .fade-leave-active {
    opacity: 0;
  }
</style>


<style lang="scss">
  @import '../../styles/front.scss';
</style>
