import Vue from 'vue';
import Router from 'vue-router';

import guestRoutes from './guest';
import userRoutes from './user';
import adminRoutes from './admin';
import moderatorRoutes from './moderator';
import * as Sentry from "@sentry/vue";


import PageNotFound from '../views/PageNotFound.vue';

Vue.use(Router);

export default new Router({
  routes: [
    ...guestRoutes,
    ...userRoutes,
    ...adminRoutes,
    ...moderatorRoutes,
    {path: '/:catchAll(.*)', component: PageNotFound},
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 10 }
      }
    } else {
      return {x: 0, y: 0}
    }
  }
});

Sentry.init({
  Vue,
  dsn: "https://1f19bea1509ba011a463e92d1efb2bcb@o4505464416763904.ingest.us.sentry.io/4506978300919808",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: ['https://aw4tm6ww14.execute-api.eu-west-1.amazonaws.com/master','https://5sj58flqdb.execute-api.eu-west-1.amazonaws.com/master', 'https://zb8i23uri4.execute-api.eu-west-1.amazonaws.com/master', 'https://sksaq0bks0.execute-api.eu-west-1.amazonaws.com/master', 'https://b4q5tw6o9k.execute-api.eu-west-1.amazonaws.com/master'],
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
