// @ts-ignore
export const addLoader = (state) => {
  state.processes += 1;
};

// @ts-ignore
export const removeLoader = (state) => {
  state.processes -= 1;
  if (state.processes < 0) {
    state.processes = 0;
  }
};

// @ts-ignore
export const addNavLoader = (state) => {
  state.navProcesses += 1;
};

// @ts-ignore
export const removeNavLoader = (state) => {
  state.navProcesses -= 1;
  if (state.navProcesses < 0) {
    state.navProcesses = 0;
  }
};
