<template>
  <v-container fluid grid-list-sm pt-5 v-if="!loading && animal">
    <v-layout row wrap>
      <v-flex xs12 sm10 offset-sm1 md8 offset-md2 xl6 offset-xl3>
        <v-layout row wrap :key="animal.id">
          <v-flex xs12 md4 class="text-xs-center">
            <s3-image
              aspect-ratio="1"
              :itemKey="animal.profileImage.replace('/full/', '/profile/')"
                      class="profile-image elevation-4"/>
          </v-flex>
          <v-flex xs12 md8>
            <h1 class="display-1 primary--text">{{animal.title}}, {{animal.age}}</h1>
            <animal-subscribe :animal="animal"></animal-subscribe>
            <p class="mt-3 subheading">
              {{animal.shortDescription}}
            </p>
            <p class="body-1">
              {{animal.longDescription}}
            </p>
          </v-flex>

          <v-flex
            v-for="post in animal.postsPreview" :key="post.id" v-if="post.preview"
            xs6
            sm4
            d-flex
            class="pa-2"
          >
            <post-preview :post="post"></post-preview>
          </v-flex>
        </v-layout>
      </v-flex>

    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import PostPreview from '@/components/Global/PostPreview.vue';
  import S3Image from '@/components/S3Image.vue';

  import AnimalSubscribe from '@/modules/guest/animals/_components/AnimalSubscribe.vue';
  import Animal from '@/types/Animal';

  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action, Getter} from 'vuex-class';

  @Component({
    components: {
      PostPreview,
      S3Image,
      AnimalSubscribe,
    },
  })
  export default class List extends Vue {
    public loading: boolean = false;

    @Prop({type: String, default: ''}) private id!: string;

    @Action('guest/animals/readOne') private readAnimal!: any;
    @Getter('guest/animals/one') public animal!: Animal;

    private async mounted() {
      this.loading = true;
      await this.readAnimal({id: this.id});
      this.loading = false;
    }
  }
</script>

<style scoped>
  .profile-image {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .img-blur {
    filter: blur(10px);
  }
</style>
