import {Auth} from 'aws-amplify';

import ModeratorBase from '@/views/Moderator/Layout.vue';
import Dashboard from '@/views/Moderator/Dashboard.vue';
import Animals from '@/views/Moderator/Animals/List.vue';
import Animal from '@/views/Moderator/Animals/Details.vue';
import Posts from '@/views/Moderator/Posts/List.vue';
import AnimalPost from "@/views/Moderator/Animals/Post.vue";

function mustBeModerator(to: any, from: any, next: any) {
  Auth.currentAuthenticatedUser().then((user) => {
    const role = user.signInUserSession.idToken.payload['cognito:groups'][0];
    if (role === 'Admin') {
      next({name: 'AdminBase'});
    } else if (role === 'Moderator') {
      next();
    } else {
      next({name: 'UserBase'});
    }
  }).catch(() => {
    next({name: 'GuestBase'});
  });
}
export default [
  {
    path: '/moderator',
    name: 'ModeratorBase',
    component: () => import(/* webpackChunkName: "ModeratorBase" */ '../views/Moderator/Layout.vue'),
    beforeEnter: mustBeModerator,
    redirect: {name: 'ModeratorPosts'},
    children: [
      {
        path: 'animals',
        name: 'ModeratorAnimals',
        component: Animals,
      },
      {
        path: 'animals/:id',
        props: true,
        name: 'ModeratorAnimal',
        component: Animal,
      },
      {
        path: 'animal/:id/post/:postId',
        name: 'ModeratorAnimalPost',
        component: AnimalPost,
        props: true,
      },
      {
        path: 'posts',
        name: 'ModeratorPosts',
        component: Posts,
      },
    ],
  },
];
