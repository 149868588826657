import Vue from 'vue';

Vue.filter('subscriptionStatus', (value: number) => {
  if (!value) {
    return '<n/a>';
  }
  switch (value) {
    case 20:
      return 'Chýbajúca platba';
    case 50:
      return 'Aktívny';
    case 90:
      return 'Zastavený';
  }

  return '';
});
