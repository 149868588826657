<template>
  <div>
    <v-btn
      v-if="animal && animal.subscription === false"
      @click.prevent="dialog = true"
      color="primary"
      class="ml-0"
    >
      {{ $t('user.animals.subscribe') }}
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      v-if="animal && animal.subscription === false"
      persistent
    >
      <v-card v-if="animal">
        <v-card-title class="primary">
          <span class="headline white--text">{{ animal.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.native="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex
                  xs12 md2
                >
                  <v-select
                    :items="genders"
                    :label="$t('user.animals.gender')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.required]"
                    v-model="gender"
                  ></v-select>
                </v-flex>
                <v-flex
                  xs12 md5
                >
                  <v-text-field
                    v-model="firstName"
                    :rules="[rules.required]"
                    :disabled="loading"
                    :label="$t('user.animals.firstName')"
                    required
                  />
                </v-flex>
                <v-flex
                  xs12 md5
                >
                  <v-text-field
                    v-model="lastName"
                    :rules="[rules.required]"
                    :disabled="loading"
                    :label="$t('user.animals.lastName')"
                    required
                  />
                </v-flex>
                <v-flex>
                  <v-select
                    :items="paymentAmounts"
                    :label="$t('user.animals.amount')"
                    item-text="text"
                    item-value="value"
                    v-model="selectedAmount"
                  ></v-select>
                </v-flex>
                <v-flex
                  xs12
                  v-if="selectedAmount === '-1'"
                >
                  <v-text-field
                    v-model="amount"
                    :rules="[rules.required, rules.minimumPayment]"
                    :disabled="loading"
                    :label="$t('user.animals.amount')"
                    :prefix="$t('user.animals.currency')"
                    type="number"
                    required
                  />
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    v-model="checkbox"
                    :rules="[rules.required]"
                    label="Adoptovaním miláčika na diaľku súhlasíte s uložením Vašich údajov do databáze priateľov
                Slobody zvierat a zasielaním pravidelného elektronického newslettru. Z odberu newslettru
                sa môžete odhlásiť kedykoľvek, pomocou odkazu na konci každého newslettru. V prípade
                otázok nás kontaktujte na utulok@slobodazvierat.sk."
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs12
                >
                  <v-radio-group v-model="paymentMethod">
                    <v-radio
                      :label="$t('user.animals.creditCard')"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      :label="$t('user.animals.paypal')"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mb-3">
          <v-spacer />
          <v-btn
            class="mb-3"
            color="primary"
            @click.native="subscribe()"
            :loading="loading"
            :disabled="!valid"
          >
            {{ $t('user.animals.subscribeButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-chip v-else-if="animal.subscription === true" class="primary lighten-3" disabled>
      <span class="white--text body-1">{{ $t('user.animals.alreadySponsor') }}</span>
    </v-chip>
  </div>
</template>

<script lang="ts">
import awsExports from '@/aws-exports.js';
import i18n from '@/i18n';
import { Auth } from 'aws-amplify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class AnimalSubscribe extends Vue {
  public $refs!: {
    form: any,
  };

  public dialog: boolean = false;
  public loading: boolean = false;
  public valid: boolean = true;
  public amount: string = '';
  public paymentMethod: number = 1;
  public firstName: string = '';
  public lastName: string = '';
  public paymentAmounts: any = [
    {
      text: '€5',
      value: '5',
    },
    {
      text: '€10',
      value: '10',
    },
    {
      text: '€20',
      value: '20',
    },
    {
      text: i18n.t('user.animals.customAmount'),
      value: '-1',
    },
  ];
  public selectedAmount: string = '5';

  public genders: any = [
    {
      text: i18n.t('user.animals.male'),
      value: 'male',
    },
    {
      text: i18n.t('user.animals.female'),
      value: 'female',
    },
  ];
  public gender: string = '';
  public checkbox: boolean = false;

  @Prop({type: Object, default: {}}) public animal!: any;
  @Getter('common/rules/all') public rules!: any;
  @Action('user/subscriptions/create') private createSubscription!: any;

  public async subscribe() {
    if (this.$refs.form.validate()) {
      this.loading = true;

      if (this.selectedAmount !== '-1') {
        this.amount = this.selectedAmount;
      }
      // const val = Math.round(100 * parseFloat(this.amount));

      // const user = await Auth.currentUserInfo();
      const user = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(user, {
        given_name: this.firstName,
        family_name: this.lastName,
        gender: this.gender,
      });

      let paymentEndpoint = '';
      if (awsExports && awsExports.aws_cloud_logic_custom instanceof Array) {
        const endpoints =
          awsExports.aws_cloud_logic_custom.filter((elem: any) => elem.name === 'slobodaPaymentApi');
        if (endpoints.length === 1) {
          paymentEndpoint = endpoints[0].endpoint;
        }
      }

      const data = {
        id_user: user.attributes.sub,
        id_animal: this.animal.id,
        client: 30,
        country: 2,
        language: 'sk',
        payment_period: 2,
        payment_type: this.paymentMethod,
        email: user.attributes.email,
        fname: this.firstName,
        lname: this.lastName,
        amount: parseFloat(this.amount),
        successUrl: `${window.location.origin}/#/user/payment/success`,
        errorUrl: `${window.location.origin}/#/user/payment/error`,
        paymentEndpoint,
      };

      const base64 = btoa(unescape(encodeURIComponent(JSON.stringify(data))));

      await this.createSubscription({...data, url: `${process.env.VUE_APP_PAYMENT_URL}?data=${base64}`});

      window.location.href = `${process.env.VUE_APP_PAYMENT_URL}?data=${base64}`;
      this.loading = false;
    }
  }

  private async created() {
    // TODO: check if there is an existing subscription
    const user = await Auth.currentUserInfo();

    this.gender = user.attributes.gender;
    this.firstName = user.attributes.given_name;
    this.lastName = user.attributes.family_name;
  }
}
</script>

<style scoped>

</style>
