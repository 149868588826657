export default {
  user: {
    username: null,
    email: null,
    oldPassword: null,
    password: null,
  },
  preferred_username: null,
  isAdmin: false,
  loggedIn: null,
  challenge: null,
};
