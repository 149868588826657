import animals from './animals';
import posts from './posts';
import comments from './comments';
import likes from './likes';

export default {
  namespaced: true,
  modules: {
    animals,
    posts,
    comments,
    likes,
  },
};
