// @ts-ignore
export const all = (state) => {
  return state.all.sort((a: any, b: any) => {
    if (a.deleted) {
      return 1;
    } else if (b.deleted) {
      return -1;
    }

    if (a.title > b.title) {
      return 1;
    } else if (b.title > a.title) {
      return -1;
    }
    return 0;
  });
};

// @ts-ignore
export const one = (state) => state.one;
// @ts-ignore
export const isAllLoaded = (state) => state.loadAll;
