// @ts-ignore
export const setUsername = (state, username) => {
  state.user.username = username;
};
// @ts-ignore
export const setPreferred_Username = (state, preferred_username) => {
  state.preferred_username = preferred_username;
};
// @ts-ignore
export const setOldPassword = (state, oldPassword) => {
  state.user.oldPassword = oldPassword;
};
// @ts-ignore
export const setPassword = (state, password) => {
  state.user.password = password;
};
// @ts-ignore
export const setChallenge = (state, res) => {
  state.challenge = res;
};
// @ts-ignore
export const setAdmin = (state, isAdmin) => {
  state.isAdmin = isAdmin;
};
// @ts-ignore
export const setUser = (state, user) => {
  state.user = user;
};
// @ts-ignore
export const setEmail = (state, email) => {
  state.user.email = email;
};
