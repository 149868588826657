import {API} from 'aws-amplify';

export const read = async (limit = 20, startKey = null) =>
  API.get('slobodaUserApi', `/posts/readAllNew`, {
    queryStringParameters: {
      limit,
      startKey,
    },
  });

export const updateAll = async () => API.get('slobodaUserApi', `/posts/updateAll`, {});

export const readOne = async (id) => API.get('slobodaUserApi', `/posts/${id}`, {});
