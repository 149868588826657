<template>
  <v-container fluid fill-height v-if="!noSubs">
    <v-layout row wrap>
      <v-flex
        xs12
        sm10 offset-sm1
        md8 offset-md2
        lg6 offset-lg3
        xl4 offset-xl4
        class="pt-3"
        v-if="posts"
        v-for="post in posts"
        :key="post.id"
      >
        <post-card :post="post" :animal="getAnimal(post.animalId)" userType="user"/>
      </v-flex >
      <v-flex xs12 v-if="!posts" class="text-xs-center">
        <Loader></Loader>
      </v-flex>
      <v-flex xs12 class="text-xs-center" v-if="!loading">
        <infinite-loading @infinite="infiniteHandler">
          <span slot="no-more"></span>
          <span slot="no-results"></span>
        </infinite-loading>
      </v-flex>
    </v-layout>
  </v-container>
  <v-container v-else fluid fill-height>
    <v-layout align-center justify-center row fill-height>
      <v-flex xs4 class="text-xs-center">
        <p class="title">{{$t('user.feed.noActiveSubscription')}}</p>
        <v-img src="/img/sad_dog.jpg" class="elevation-5"></v-img>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import PostCard from '@/components/Global/PostCard.vue';
  import {Component, Vue} from 'vue-property-decorator';
  import {Action, Getter} from 'vuex-class';
  import PostCommentField from '@/modules/user/comments/_components/PostCommentField.vue';
  import S3Image from '@/components/S3Image.vue';
  import PostLikeField from '@/modules/user/likes/_components/PostLikeField.vue';
  import DeleteCommentField from '@/modules/user/comments/_components/DeleteCommentField.vue';
  import Loader from '@/components/Loader.vue';
  import InfiniteLoading from 'vue-infinite-loading';
  import S3Video from '@/components/S3Video.vue';

  @Component<Dashboard>({
    components: {
      PostCard,
      S3Video,
      Loader,
      DeleteCommentField,
      PostLikeField,
      S3Image,
      PostCommentField,
      InfiniteLoading,
    },
    beforeRouteEnter(to, from, next) {
      next((vm: Dashboard) => {
        vm.from = from;
      });
    },
  })
  export default class Dashboard extends Vue {
    public from: any;
    public loading: boolean = true;
    public noSubs: boolean = false;

    @Getter('user/posts/all') public posts!: any;
    @Getter('user/posts/isAllLoaded') private isAllLoaded!: boolean;
    @Getter('user/animals/all') private animals!: any[];

    @Action('user/animals/read') private readAnimals!: any;
    @Action('user/posts/read') private readPosts!: any;
    @Action('user/posts/infiniteRead') private infiniteReadPosts!: any;

    private async mounted() {
      this.loading = true;

      try {
        await this.readAnimals();
        await this.readPosts();

        if (!this.posts || this.posts.length === 0) {
          if (this.from && ['SignIn', 'SignUp', 'GuestHome'].indexOf(this.from.name) !== -1) {
            this.$router.push({name: 'UserAnimals'});
          }
        }
        while (this.posts.length === 0 && !this.isAllLoaded) {
          await this.infiniteReadPosts();
        }

        if (this.posts.length === 0) {
          this.noSubs = true;
        }
        this.loading = false;
      } catch (ex) {
        this.loading = false;
      }
    }

    public getAnimal(id: string) {
      for (const animal in this.animals) {
        if (this.animals[animal].id === id) {
          return this.animals[animal];
        }
      }
      return null;
    }

    public async infiniteHandler($state) {
      if (this.isAllLoaded) {
        return $state.complete();
      }
      await this.infiniteReadPosts();
      return $state.loaded();
    }

  }
</script>

<style scoped>

</style>
