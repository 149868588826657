<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-tooltip bottom slot="activator">
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <v-icon color="error">delete</v-icon>
        </v-btn>
      </template>
      <span>Delete</span>
    </v-tooltip>

    <v-card>
      <v-card-title class="primary" >
        <span class="headline white--text">Remove Moderator</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="my-3">
        Do you really want to delete "{{moderator.email}}"?
      </v-card-text>
      <v-card-actions class="mb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          @click.native="del()"
          :loading="loading"
          class="mb-3"
        >
          Delete
        </v-btn>

        <v-btn
          @click.native="dialog = false"
          class="mb-3"
          color="primary"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  // import { VueEditor } from 'vue2-editor';
  import { mapActions } from 'vuex';


  export default {
    name: 'DlgModeratorsDelete',
    components: {
      // VueEditor,
    },
    data() {
      return {
        dialog: false,
        loading: false,
      };
    },
    props: ['moderator'],
    methods: {
      ...mapActions({
        memberDel: 'admin/moderators/remove',
        memberRead: 'admin/moderators/read',
      }),
      del() {
        this.loading = true;
        this.memberDel(this.moderator)
          .then((res) => {
            this.loading = false;
            if (res) {
              this.memberRead();
              this.dialog = false;
            }
          });
      },
    },
  };
</script>

<style scoped>

</style>
