import Vue from 'vue';
import router from '@/router';
import store from '@/store';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import './plugins/vuetify';
import './plugins/chartist';
import './plugins/amplify';

import './filters/stringToDate';
import './filters/timeAgo';
import './filters/parseComment';
import './filters/subscriptionStatus';

import App from './App.vue';
import i18n from './i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
