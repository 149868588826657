import * as API from '../_api';

// @ts-ignore
export const create = async ({ dispatch }, one) => {
  try {
    await API.create(one);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};

// @ts-ignore
export const del = async ({ dispatch }, one) => {
  try {
    await API.del(one);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};
