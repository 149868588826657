import * as API from '../_api';

import IModerator from '../_types/moderator';
import IStore from '../_types/vuex';

export const read = async ({ commit, dispatch }: IStore) => {
  try {
    const all = await API.read();
    commit('setAll', all);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};

export const create = async ({ commit, dispatch }: IStore, { username }: IModerator) => {
  try {
    await API.create({ username, email: username });
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};

export const forgot = async ({ commit, dispatch }: IStore, moderator: IModerator) => {
  try {
    await API.forgot(moderator);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};

export const remove = async ({ commit, dispatch }: IStore, { username }: IModerator) => {
  try {
    await API.del({ username });
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', { root: true });
    return false;
  }
};
