import * as API from '../_api';

import i18n from '@/i18n';

// @ts-ignore
export const create = async ({ dispatch }, { post, commentText }) => {
  try {
    await API.create(post, commentText);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', i18n.t('user.error'), { root: true });
    return false;
  }
};
// @ts-ignore
export const del = async ({ dispatch }, comment) => {
  try {
    await API.del(comment);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', i18n.t('user.error'), { root: true });
    return false;
  }
};
