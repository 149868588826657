<template>
  <v-card flat tile class="d-flex">
    <div style="position: relative" class="elevation-2 overflow-hidden">
      <v-img
        :src="'https://slobodae4e445ed49294cb59c51f615a8e56e17-master.s3.eu-west-1.amazonaws.com/public/' + post.preview.replace('/full/', '/preview/')"
        aspect-ratio="1"
        class="grey lighten-2 img-blur"
      >
        <template v-slot:placeholder>
          <v-layout
            fill-height
            align-center
            justify-center
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-layout>
        </template>
      </v-img>
    </div>
  </v-card>
</template>

<script lang="ts">
import Post from '@/types/Post';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AnimalCard extends Vue {
  @Prop({default: {id: '', preview: ''}}) public post!: Post;
}
</script>

<style scoped lang="scss">
.img-blur {
  filter: blur(10px);
}
</style>
