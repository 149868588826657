<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-btn icon flat small style="margin: 0; color: red" :loading="loading" slot="activator">
      <v-icon color="error">delete</v-icon>
    </v-btn>
    <v-card>
      <v-card-title class="primary" >
        <span class="headline white--text">Remove Subscription</span>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="my-3">
        Do you really want to remove the subscription?
      </v-card-text>

      <v-card-actions class="mb-3">
        <v-spacer></v-spacer>

        <v-btn
          color="error"
          @click="deleteSubscription()"
          class="mb-3"
        >
          Delete
        </v-btn>

        <v-btn
          @click="dialog = false"
          class="mb-3"
          color="primary"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action} from 'vuex-class';

  @Component
  export default class DlgSubscriptionsDelete extends Vue {
    @Prop({type: Object, default: {}}) private subscription!: any;

    private loading: boolean = false;
    private dialog: boolean = false;

    @Action('admin/subscriptions/del') private storeDeleteSubscription!: (subscription: any) => Promise<boolean>;
    @Action('admin/users/readOne') private readUser!: (id: string) => Promise<boolean>;

    private deleteSubscription() {
      this.loading = true;
      this.storeDeleteSubscription({id: this.subscription.id})
        .then((success) => {
          if (!success) {
            throw new Error('Error while creating subscription');
          }
          return this.$emit('done');
        })
        .then(() => {
          this.loading = false;
          this.dialog = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
</script>

<style scoped>

</style>
