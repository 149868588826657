<template>
  <v-card
    v-bind="$attrs"
    :style="styles"
  >
    <helper-offset
      v-if="hasOffset"
      :inline="inline"
      :full-width="fullWidth"
      :offset="offset"
    >
      <v-card
        v-if="!$slots.offset"
        :color="color"
        :class="`elevation-${elevation}`"
        class="v-card--material__header"
        dark
      >
        <slot
          v-if="!title && !text"
          name="header"
        />
        <v-card-title v-else>
          <div>
            <h4
              class="title font-weight-light mb-2"
              v-text="title"
            />
            <p
              class="category font-weight-thin"
              v-text="text"
            />
          </div>
          <v-spacer></v-spacer>
          <div v-if="value !== undefined">
            <v-text-field
              append-icon="search"
              label="Suche"
              single-line
              hide-details
              v-model="content"
              @input="handleInput"
              ref="search"
            ></v-text-field>
          </div>
        </v-card-title>
      </v-card>
      <slot
        v-else
        name="offset"
      />
    </helper-offset>

    <v-card-text>
      <slot />
    </v-card-text>

    <v-divider
      v-if="$slots.actions"
      class="mx-3"
    />

    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
  import HelperOffset from '../helper/Offset';

  export default {
    title: 'Card',
    components: {
      HelperOffset,
    },
    inheritAttrs: false,

    props: {
      color: {
        type: String,
        default: 'secondary',
      },
      elevation: {
        type: [Number, String],
        default: 10,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      offset: {
        type: [Number, String],
        default: 24,
      },
      title: {
        type: String,
        default: undefined,
      },
      text: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
    },

    data() {
      return {
        content: this.value,
      };
    },

    computed: {
      hasOffset() {
        return this.$slots.header
          || this.$slots.offset
          || this.title
          || this.text;
      },
      styles() {
        if (!this.hasOffset) {
          return null;
        }

        return {
          marginBottom: `${this.offset}px`,
          marginTop: `${this.offset * 2}px`,
        };
      },
    },

    methods: {
      handleInput() {
        this.$emit('input', this.content);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-card--material {
    &__header {
      &.v-card {
        border-radius: 4px;
      }
    }
  }

  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }

  .v-card__title {
    padding: 0;
  }

  .v-card > :first-child:not(.v-btn):not(.v-chip) {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .v-card .v-offset {
    top: -20px !important;
    margin-bottom: -20px !important;
  }

  .v-offset {
    margin: 0 auto;
    max-width: calc(100% - 32px);
    position: relative;
  }

  .theme--light.v-card {
    background-color: #fff;
    border-color: #fff;
    color: rgba(0, 0, 0, .87);
  }

  .v-card {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .14);
    border-radius: 3px;
  }

  .v-card {
    border-radius: 2px;
    -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: block;
    min-width: 0;
    position: relative;
    text-decoration: none;
    -webkit-transition: .3s cubic-bezier(.25, .8, .5, 1);
    transition: .3s cubic-bezier(.25, .8, .5, 1);
  }

  .v-card .v-offset .v-card--material__header.v-card {
    padding: 15px;
  }

  .v-card.green {
    background: linear-gradient(60deg, #66bb6a, #43a047) !important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(76, 175, 80, .28), 0 4px 20px 0 rgba(0, 0, 0, .12), 0 7px 8px -5px rgba(76, 175, 80, .2) !important;
    box-shadow: 0 12px 20px -10px rgba(76, 175, 80, .28), 0 4px 20px 0 rgba(0, 0, 0, .12), 0 7px 8px -5px rgba(76, 175, 80, .2) !important;
  }

  .v-card--material__header.v-card {
    border-radius: 4px;
  }
</style>
