<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl4>
        <v-form ref="form" @submit="signIn">
          <v-card class="elevation-12">
            <v-card-title primary-title class="primary">
              <div class="headline white--text">{{$t('auth.login')}}</div>
            </v-card-title>
            <v-card-text>
              <v-text-field
                autocomplete="email"
                prepend-icon="mail"
                name="email"
                :label="$t('auth.email')"
                :disabled="loading"
                :rules="[ rules.required, rules.email ]"
                v-model="user.username"
                type="text">
              </v-text-field>
              <v-text-field
                autocomplete="current-password"
                id="password"
                prepend-icon="lock"
                name="password"
                :label="$t('auth.password')"
                :disabled="loading"
                v-model="user.password"
                :rules="[ rules.required, rules.password ]"
                type="password">
              </v-text-field>
              <router-link :to="{ name: 'SignUp' }">{{$t('auth.signUp.title')}}</router-link>&nbsp;
              <router-link :to="{ name: 'ForgotPassword' }">{{$t('auth.forgotPassword.title')}}</router-link>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="primary"
                :loading="loading"
                @click="signIn">
                {{$t('auth.login')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import ICognitoUserData from '@/types/cognitoUserData';
  import {
    Getter,
    Action,
  } from 'vuex-class';

  @Component
  export default class SignIn extends Vue {
    public $refs!: {
      form: any,
    };

    private loading: boolean = false;
    private user: any = {
      username: null,
      password: null,
    };

    @Getter('guest/auth/username') private username!: string;
    @Action('guest/auth/signIn') private storeSignIn!: (user: ICognitoUserData) => Promise<any>;
    @Getter('guest/auth/challenge') private challenge!: any;
    @Getter('common/rules/all') private rules!: any[];

    public mounted() {
      if (this.username) {
        this.user.username = this.username;
      }
    }

    private async signIn(e: Event) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;

        // TODO: Check if true or false is returned. If it is true, login was successful.
        // TODO: If it is false, login was unsuccessful and there might be a challenge
        const success = await this.storeSignIn(this.user);
        this.loading = false;

        if (success) {
          this.$router.push({name: 'UserBase'});
        } else {
          switch (this.challenge) {
            case 'UserNotConfirmedException':
              this.$router.push({name: 'ConfirmSignUp'});
              break;
            case 'PasswordResetRequiredException':
              this.$router.push({name: 'CompleteNewPassword'});
              break;
            case 'RESET_REQUIRED':
              this.$router.push({name: 'ForgotPasswordSubmit'});
              break;
            case 'NEW_PASSWORD_REQUIRED':
              this.$router.push({name: 'NewPasswordRequired'});
              break;
          }
        }
        // .then((res: any) => {
        //   this.loading = false;
        //   if (res && res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //     this.$router.push({ name: 'CompleteNewPassword' });
        //   } else if (res && res.code === 'RESET_REQUIRED') {
        //     this.$router.push({ name: 'ForgotPasswordSubmit' });
        //   } else {
        //     this.$router.push({ name: 'UserBase' });
        //   }
        // });
      }
    }
  }
</script>

<style scoped>
</style>
