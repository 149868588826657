import loader from './loader';
import rules from './rules';
import snackbar from './snackbar';
import ui from './ui';

export default {
  namespaced: true,
  modules: {
    loader,
    rules,
    snackbar,
    ui,
  },
};
