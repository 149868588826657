<template>
  <v-container fluid fill-height>
    <v-layout row wrap>
      <v-flex xs12 sm8 offset-sm2 lg8 offset-lg2 xl6 offset-xl3>
        <h1 class="display-1 font-weight-light mb-4 text-uppercase primary--text">Zvieratá</h1>
      </v-flex>

      <v-flex xs12 sm8 offset-sm2 lg8 offset-lg2 xl6 offset-xl3>
        <v-layout row wrap>
          <v-flex
            v-if="animals"
            v-for="(animal) in filteredAnimals"
            :key="animal.id"
            xs12 sm6 md4
            class="pa-2 d-flex"
          >
            <router-link :to="{ name: 'UserAnimal', params: { id: animal.id }}" style="display: flex; flex-grow: 1"
              class="no-link">
              <animal-card :animal="animal">
                <div class="px-3 pb-3">
                  <animal-subscribe :animal="{...animal, subscription: animal.subscribed}"
                    v-if="!animal.deleted"></animal-subscribe>
                </div>
              </animal-card>
            </router-link>
          </v-flex>

          <v-flex xs12 v-if="loading" class="text-xs-center">
            <Loader></Loader>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import AnimalCard from '@/components/Global/AnimalCard.vue';
import Loader from '@/components/Loader.vue';
import S3Image from '@/components/S3Image.vue';
import AnimalSubscribe from '@/modules/user/subscriptions/_components/AnimalSubscribe.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    AnimalCard,
    Loader,
    AnimalSubscribe,
    S3Image,
  },
})
export default class AnimalsList extends Vue {
  public loading: boolean = false;
  @Action('user/animals/read') private readAnimals!: () => Promise<boolean>;
  @Getter('user/animals/all') public animals!: any[];

  private mounted() {
    this.loading = true;

    this.readAnimals()
      .then((success) => {
        if (success) {
          this.loading = false;
        } else {
          throw new Error('Some Error, handle it');
        }
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  public get filteredAnimals() {
    return this.animals.filter((animal) => {
      return animal.subscribed || !animal.deleted;
    });
  }
}
</script>

<style scoped>
</style>
