import {API, Storage} from 'aws-amplify';

// @ts-ignore
function cleanEmptyValue(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === '') {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });

  return obj;
}

// @ts-ignore
export const create = async (post) => API.put(
  'slobodaModeratorApi',
  '/posts',
  {body: cleanEmptyValue(post)},
);

export const read = async (startKey = null) => API.get('slobodaModeratorApi', `/posts`,
  {
    queryStringParameters: {
      startKey,
    },
  },
);

export const readOne = async (id) => API.get('slobodaModeratorApi', `/posts/${id}`, {});

// @ts-ignore
export const update = async (post) => API.post('slobodaModeratorApi', `/posts/${post.id}`, {
  body:
    cleanEmptyValue(post),
});

// @ts-ignore
export const del = async (post) => API.del('slobodaModeratorApi', `/posts/${post.id}`, {});

// @ts-ignore
export const uploadImage = (name, image) => {
  // TODO: check if file exists already
  return Storage.put(name, image, {level: 'public', contentType: 'image/png'});
};

// @ts-ignore
export const uploadVideo = (name, video) => {
  // TODO: check if file exists already
  return Storage.put(name, video, {level: 'public', contentType: 'image/png'});
};

