import * as API from '../_api';
//
// import uuidv4 from 'uuid/v4';
//
// const randomId = () => `_${Math.random().toString(36).substr(2, 9)}`;

// @ts-ignore
export const create = async ({dispatch}, {post, commentText}) => {
  try {
    await API.create(post, commentText);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', {root: true});
    return false;
  }
};
//
// // @ts-ignore
// export const read = async ({ commit, dispatch }) => {
// };
//
// // @ts-ignore
// export const update = async ({ dispatch }, one) => {
// };

// @ts-ignore
export const del = async ({dispatch}, comment) => {
  try {
    await API.del(comment);
    return true;
  } catch (ex) {
    dispatch('common/snackbar/error', 'Fatal Error. Please try again later', {root: true});
    return false;
  }
};
