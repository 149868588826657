<template>
  <v-card class="hover-effect elevation-1">
    <div style="overflow: hidden">
      <v-img
          class="hover-image"
          :src="'https://slobodae4e445ed49294cb59c51f615a8e56e17-master.s3.eu-west-1.amazonaws.com/public/' + animal.profileImage.replace('/full/', '/large/')"
          :lazy-src="'https://slobodae4e445ed49294cb59c51f615a8e56e17-master.s3.eu-west-1.amazonaws.com/public/' + animal.profileImage.replace('/full/', '/tiny/')"
          aspect-ratio="1"
          :class="{'deleted': animal.deleted}"
      >
        <template v-slot:placeholder>
          <v-layout
              fill-height
              align-center
              justify-center
              ma-0
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-layout>
        </template>
      </v-img>
    </div>
    <div class="mt-3 card-header">
      <h2 class="headline primary--text">{{ animal.title }}, {{ animal.age }}</h2>
    </div>
    <div class="card-description px-2 pb-3">
      <span class="d-block body-1 mx-3 mt-2">{{ animal.shortDescription }}</span>
    </div>
    <div class="card-footer">
      <slot></slot>
    </div>
  </v-card>
</template>

<script lang="ts">
import S3Image from '@/components/S3Image.vue';
import AnimalSubscribe from '@/modules/user/subscriptions/_components/AnimalSubscribe.vue';
import Animal from '@/types/Animal';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AnimalSubscribe,
    S3Image,
  },
})
export default class AnimalCard extends Vue {
  @Prop({default: {profileImage: '', id: '', title: '', shortDescription: ''}}) public animal!: Animal;
}
</script>

<style scoped lang="scss">
.v-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-image {
  width: 100%;
  padding-top: 100%;
  background-size: cover;
}

.card-header, .card-footer {
  text-align: center;
}

.card-header h2 {
  margin: 0; /* Adjust as needed */
}

.card-description {
  flex-grow: 1;
}

.deleted {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.radius {
  border-radius: 20px;
}

.hover-image {
  transition: all 0.5s;
}

.hover-effect:hover {
  .hover-image {
    transform: scale(1.1);
  }
}

.deleted {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

</style>
