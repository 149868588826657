// @ts-ignore
export const setAll = (state, all) => {
  state.all = all;
};

// @ts-ignore
export const setOne = (state, one) => {
  if (state.one.id === one.id) {
    state.one.posts = state.loadAll ? one.posts : [...state.one.posts, ...one.posts];
  } else {
    state.one = one;
  }
  if (one.lastKey !== null) {
    state.lastEvaluatedKey = one.lastKey.id;
  } else {
    state.lastEvaluatedKey = null;
    state.loadAll = true;
  }
};
