<template>
  <section
    id="screenshots2"
    class="hide-overflow"
  >
    <v-layout>
      <v-flex
        xs12
        md6
        align-content-space-between
        layout
        pa-5
        wrap
      >
        <v-layout
          align-center
          justify-center
          fill-height
        >
          <v-flex
            xs10
            md6
          >
            <h1 class="display-1 v-heading mb-3 font-weight-light">Welcome!</h1>
            <p
            >
              Lorem ipsum dolor sit amet, consectetur ad
              ipiscin elit. Etiam vulputate augue vel felis gra
              vida porta. Lorem ipsum dolor sit amet, cons
              ectetur adipiscing elit.<br>
              Lorem ipsum dolor sit amet, consectetur ad
              ipiscin elit. Etiam vulputate augue vel felis gra
              vida porta. Lorem ipsum dolor sit amet, cons
              ectetur adipiscing elit.
            </p>

            <v-btn
              color="secondary"
              rounded
            >
              Learn More!
            </v-btn>
          </v-flex>
        </v-layout>

      </v-flex>
      <v-flex
        hidden-sm-and-down
        md6
      >
        <v-img
          src="/img/screenshots/2.jpg"
        />
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
  export default {
    name: 'GuestScreenshots2',
  };
</script>

<style scoped>

</style>
