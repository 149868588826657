<template>
  <div class="text-xs-center">
    <video controls v-if="video">
      <source :src="video"
              @click="pickFile"
              :loading="loading">
    </video>
    <v-img
      class="clickable"
      @click="pickFile"
      src="img/playButton.png"
    />
    <input
      ref="image"
      type="file"
      style="display: none"
      accept="video/*"
      @change="onFilePicked"
    >
  </div>
</template>

<script lang="ts">
  import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
  import {Storage} from 'aws-amplify';

  @Component
  export default class VideoSelector extends Vue {
    public $refs!: {
      image: any,
    };

    private loading: boolean = false;
    private imageName: string = '';
    private imageFile: string = '';
    private image: string | object = 'placeholder.png';
    private rotate: number = 0;
    private video: string = '';

    private rotation: any = {
      1: 'rotate(0deg)',
      3: 'rotate(180deg)',
      6: 'rotate(90deg)',
      8: 'rotate(270deg)',
    };

    @Prop({type: Object, default: null}) private width!: any;
    @Prop({type: String, default: 'Logo'}) private fieldName!: any;
    @Prop({type: Boolean, default: false}) private showText!: boolean;
    @Prop({type: File, default: null}) private value!: any;
    @Prop({type: String, default: ''}) private url!: any;
    @Prop({type: Array, default: () => [(v) => !!v || 'Dies ist ein Pflichtfeld']}) private rules!: any;

    private mounted() {
      // tslint:disable-next-line
      // console.log(awsExports.aws_user_files_s3_bucket);
    }

    private created() {
      if (this.url) {
        this.init(this.url);
      }
    }

    private async init(itemKey) {
      this.image = await Storage.get(itemKey, {level: 'public'});
    }

    @Watch('value')
    private updatedValue() {
      this.loading = true;

      if (!this.value) {
        this.loading = false;
        this.imageName = '';
        this.imageFile = '';
        this.image = 'placeholder.png';
        this.$refs.image.value = '';
      } else {
        this.imageName = this.value.name;
        const fr = new FileReader();
        fr.readAsDataURL(this.value);
        fr.addEventListener('load', (res: any) => {
          this.image = res.target.result;
          this.loading = false;
        });
      }
    }

    private pickFile() {
      this.$refs.image.click();
    }

    private onFilePicked(e: any) {
      this.loading = true;

      const files = e.target.files;
      if (files[0] !== undefined) {
        // this.orientation(files[0], (base64, rotation) => {
        //   this.rotate = rotation;
        // });

        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf('.') <= 0) {
          this.loading = false;
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', (res: any) => {
          this.video = res.target.result;


          this.$emit('input', files[0]);
          this.loading = false;
        });
      }
    }

    private doUpload() {
      // return this.uploadImage(this.imageFile);
    }

    private arrayBufferToBase64(buffer) {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }

    //
    private orientation(file, callback) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const base64img = 'data:' + file.type + ';base64,' + this.arrayBufferToBase64(fileReader.result);
        // @ts-ignore
        const scanner = new DataView(fileReader.result);
        let idx = 0;
        let value = 1; // Non-rotated is the default
        // @ts-ignore
        if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xFFD8) {
          // Not a JPEG
          if (callback) {
            callback(base64img, value);
          }
          return;
        }
        idx += 2;
        let maxBytes = scanner.byteLength;
        let littleEndian = false;
        while (idx < maxBytes - 2) {
          const uint16 = scanner.getUint16(idx, littleEndian);
          idx += 2;
          switch (uint16) {
            case 0xFFE1: // Start of EXIF
              const endianNess = scanner.getUint16(idx + 8);
              // II (0x4949) Indicates Intel format - Little Endian
              // MM (0x4D4D) Indicates Motorola format - Big Endian
              if (endianNess === 0x4949) {
                littleEndian = true;
              }
              const exifLength = scanner.getUint16(idx, littleEndian);
              maxBytes = exifLength - idx;
              idx += 2;
              break;
            case 0x0112: // Orientation tag
              // Read the value, its 6 bytes further out
              // See page 102 at the following URL
              // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
              value = scanner.getUint16(idx + 6, littleEndian);
              maxBytes = 0; // Stop scanning
              break;
          }
        }
        if (callback) {
          callback(base64img, value);
        }
      };
      fileReader.readAsArrayBuffer(file);
    }
  }
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>
