<template>
  <v-textarea
    v-model="commentText"
    auto-grow
    box
    label="Comment..."
    rows="1"
    append-icon="send"
    @click:append="postComment"
    :disabled="loading"
    :loading="loading"
    @keydown.enter.exact.prevent
    @keyup.enter.exact="postComment"
    hide-details
  ></v-textarea>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator';
  import {Action} from 'vuex-class';

  @Component
  export default class PostCommentField extends Vue {
    @Prop({type: Object, default: {}}) private post!: any;

    private commentText: string = '';
    private loading: boolean = false;

    @Action('moderator/comments/create') private storePostComment!: ({ post, commentText}) => Promise<boolean>;
    @Action('moderator/posts/readOne') private readPost!: ({ id }) => Promise<boolean>;

    private postComment() {
      this.loading = true;
      this.storePostComment({ post: this.post, commentText: this.commentText })
        .then((success) => {
          if (!success) {
            throw new Error('Error while creating comment');
          }
          return this.readPost(this.post);
        })
        .then((post) => {
          this.$emit('update', post);
          this.commentText = '';
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
</script>

<style scoped>

</style>
