<template>
  <section
    id="screenshots1"
    class="hide-overflow white py-5"
  >
    <v-layout>
      <v-flex
        xs12
        md6
        offset-md3
        align-content-space-between
        layout
        pa-5
        wrap
      >
        <v-layout
          align-center
          justify-center
          fill-height
        >
          <v-flex
            xs10
            md12
          >
            <h2 class="display-1 v-heading mb-4 font-weight-light text-xs-center text-uppercase">Kto sme my – Sloboda zvierat</h2>
            <p class="body-1">
              Sme občianske združenie, ktoré funguje na Slovensku už viac ako 25 rokov. Centrálny útulok pre zvieratá sa
              nachádza v Bratislave a jeho obsadenosť málokedy klesne pod hranicu 200 zvierat. Jedná sa prevažne o
              psíkov a mačky. Ročne zachránime a umiestnime viac ako 2000 zvierat. Venujeme sa ich priamej záchrane, a
              tiež osvete. Našou súčasťou je ďalších 24 regionálnych centier po celom Slovensku. Útulok prevádzkujeme
              výhradne vďaka finančným darom našich podporovateľov.
            </p>
          </v-flex>
        </v-layout>

      </v-flex>
    </v-layout>
  </section>
</template>

<script>
  export default {
    name: 'GuestAbout',
  };
</script>

<style scoped>

</style>
