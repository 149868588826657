<template>
  <div>404</div>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';

  @Component
  export default class PageNotFound extends Vue {
  }
</script>

<style scoped>

</style>
