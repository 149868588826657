<template>
  <div class="text-xs-center">
    <div v-if="fileType === 'video'"
      style="display: flex; flex-direction: column; justify-content: center; align-items: center">
      <video controls v-if="video" style="max-width: 600px">
        <source :src="video">
      </video>
      <v-btn color="primary" @click="pickFile">Change</v-btn>
    </div>
    <v-img
      v-if="fileType === 'image' || fileType === ''"
      :src="image"
      style="cursor: pointer;"
      :style="{'transform': rotation[rotate]}"
      @click="pickFile"
      :loading="loading"
    ></v-img>
    <v-text-field
      :class="{ 'hidden': !showText }"
      v-model="mediaName"
      :rules="rules"
      :label="fieldName"
      prepend-icon="attach_file"
      @click="pickFile"
    />
    <input
      ref="media"
      type="file"
      style="display: none"
      accept="image/*,video/*"
      @change="onFilePicked"
    >
  </div>
</template>

<script lang="ts">
import { Storage } from 'aws-amplify';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MediaSelector extends Vue {
  public $refs!: {
    media: any,
  };

  public loading: boolean = false;
  public mediaName: string = '';
  public video: string = '';
  public image: string | object = 'placeholder.png';
  public rotate: number = 0;
  public fileType = '';

  public rotation: any = {
    1: 'rotate(0deg)',
    3: 'rotate(180deg)',
    6: 'rotate(90deg)',
    8: 'rotate(270deg)',
  };

  @Prop({type: String, default: 'Logo'}) public fieldName!: any;
  @Prop({type: Boolean, default: false}) public showText!: boolean;
  @Prop({type: File, default: null}) private value!: any;
  @Prop({type: String, default: ''}) private url!: any;
  @Prop({type: Array, default: () => [(v: any) => !!v || 'Dies ist ein Pflichtfeld']}) public rules!: any;

  private created() {
    if (this.url) {
      this.init(this.url);
    }
  }

  private async init(itemKey: string) {
    const file = await Storage.get(itemKey, {level: 'public'});
    this.video = file;
    this.image = file;
  }

  @Watch('value')
  private updatedValue() {
    this.loading = true;

    if (!this.value) {
      this.loading = false;
      this.mediaName = '';
      this.image = 'placeholder.png';
      this.$refs.media.value = '';
    } else {
      this.mediaName = this.value.name;
      const fr = new FileReader();
      fr.readAsDataURL(this.value);
      fr.addEventListener('load', (res: any) => {
        this.image = res.target.result;
        this.loading = false;
      });
    }
  }

  public pickFile() {
    this.$refs.media.click();
  }

  public onFilePicked(e: any) {
    this.loading = true;

    const files = e.target.files;
    if (files[0] !== undefined) {
      this.mediaName = files[0].name;
      if (this.mediaName.lastIndexOf('.') <= 0) {
        this.loading = false;
        return;
      }
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', (res: any) => {

        if (files[0].type.startsWith('video/')) {
          this.fileType = 'video';
          this.video = res.target.result;
        } else {
          this.orientation(files[0], (_: any, rotation: number) => {
            this.rotate = rotation;
          });
          this.fileType = 'image';
          this.image = res.target.result;
        }

        this.$emit('input', files[0]);
        this.loading = false;
      });
    }
  }

  private arrayBufferToBase64(buffer: string | ArrayBuffer | null) {
    if (!buffer || typeof buffer === 'string') {
      return '';
    }
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private orientation(file: File, callback: (base64img: string, value: number) => void) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const base64img = 'data:' + file.type + ';base64,' + this.arrayBufferToBase64(fileReader.result);
      // @ts-ignore
      const scanner = new DataView(fileReader.result);
      let idx = 0;
      let value = 1; // Non-rotated is the default
      // @ts-ignore
      if (fileReader.result.length < 2 || scanner.getUint16(idx) !== 0xFFD8) {
        // Not a JPEG
        if (callback) {
          callback(base64img, value);
        }
        return;
      }
      idx += 2;
      let maxBytes = scanner.byteLength;
      let littleEndian = false;
      while (idx < maxBytes - 2) {
        const uint16 = scanner.getUint16(idx, littleEndian);
        idx += 2;
        switch (uint16) {
          case 0xFFE1: // Start of EXIF
            const endianNess = scanner.getUint16(idx + 8);
            // II (0x4949) Indicates Intel format - Little Endian
            // MM (0x4D4D) Indicates Motorola format - Big Endian
            if (endianNess === 0x4949) {
              littleEndian = true;
            }
            const exifLength = scanner.getUint16(idx, littleEndian);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112: // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            value = scanner.getUint16(idx + 6, littleEndian);
            maxBytes = 0; // Stop scanning
            break;
        }
      }
      if (callback) {
        callback(base64img, value);
      }
    };
    fileReader.readAsArrayBuffer(file);
  }
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
