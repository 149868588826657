import Vue from 'vue';

export const setAll = (state, all) => {
  state.all = all.data;
  if (all && all.lastKey) {
    state.lastEvaluatedKey = all.lastKey.id;
    state.loadAll = false;
  } else {
    state.lastEvaluatedKey = null;
    state.loadAll = true;
  }
};

export const concatPosts = (state, all) => {
  if (all.data) {
    state.all = state.all.concat(all.data);
  }
  if (all && all.lastKey !== null) {
    state.lastEvaluatedKey = all.lastKey.id;
  } else {
    state.lastEvaluatedKey = null;
    state.loadAll = true;
  }
};

// @ts-ignore
export const setOne = (state, one) => {
  state.one = one;

  for (const i in state.all) {
    if (state.all.hasOwnProperty(i)) {
      if (state.all[i].id === one.id) {
        Vue.set(state.all, i, one);
      }
    }
  }
};
