import i18n from '@/i18n';

function parseJoiMessage(message: any) {
  if (i18n.te(`errors.${message.code ? message.code : message}`)) {
    message = i18n.t(`errors.${message.code ? message.code : message}`);
  }

  const fields: any[] = [];
  if (message.response && message.response.data && message.response.data.errors) {
    for (const field of message.response.data.errors) {
      fields.push(field.messages[0]);
    }
    return fields.join('<br/>');
  } else if (message.message) {
    return message.message;
  } else if (message.msg) {
    return message.msg;
  }
  return message;
}

// @ts-ignore
export const stop = async ({ commit }) => {
  commit('stop');
};

// @ts-ignore
export const warning = async ({ commit }, message) => {
  commit('start', { message: parseJoiMessage(message), color: 'warning' });
};

// @ts-ignore
export const danger = async ({ commit }, message) => {
  commit('start', { message: parseJoiMessage(message), color: 'error' });
};

// @ts-ignore
export const error = async ({ commit }, message) => {
  commit('start', { message: parseJoiMessage(message), color: 'error' });
};

// @ts-ignore
export const notify = async ({ commit }, message) => {
  commit('start', { message: parseJoiMessage(message), color: 'orange darken-1' });
};

// @ts-ignore
export const info = async ({ commit }, message) => {
  commit('start', { message: parseJoiMessage(message), color: 'info' });
};

// @ts-ignore
export const success = async ({ commit }, message) => {
  commit('start', { message: parseJoiMessage(message), color: 'success' });
};
