<template>
  <div>
    <v-btn
      @click.prevent="dialog = true"
      color="primary"
      class="ml-0"
    >
      {{ $t('user.animals.subscribe') }}
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">{{ animal.title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.native="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit="createAccount"
          >
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>
                  <router-link :to="{ name: 'SignIn' }">{{ $t('auth.existing_account') }}</router-link>
                </v-flex>
                <v-flex v-if="!loggedIn" xs12>
                  <v-text-field
                    autocomplete="email"
                    name="email"
                    :label="$t('auth.email')"
                    :rules="[ rules.email, rules.required ]"
                    :disabled="loading"
                    v-model="user.username"
                    type="text">
                  </v-text-field>
                </v-flex>
                <v-flex v-if="!loggedIn" xs12>
                  <v-text-field
                    autocomplete="new-password"
                    id="password"
                    name="password"
                    :label="$t('auth.password')"
                    :rules="[ rules.required, rules.password ]"
                    :disabled="loading"
                    v-model="user.password"
                    type="password">
                  </v-text-field>
                </v-flex>
                <v-flex
                  xs12 md2
                >
                  <v-select
                    :items="genders"
                    :label="$t('user.animals.gender')"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.required]"
                    :disabled="loading"
                    v-model="gender"
                  ></v-select>
                </v-flex>
                <v-flex
                  xs12 md5
                >
                  <v-text-field
                    v-model="firstName"
                    :rules="[rules.required]"
                    :disabled="loading"
                    :label="$t('user.animals.firstName')"
                    required
                  />
                </v-flex>
                <v-flex
                  xs12 md5
                >
                  <v-text-field
                    v-model="lastName"
                    :rules="[rules.required]"
                    :disabled="loading"
                    :label="$t('user.animals.lastName')"
                    required
                  />
                </v-flex>
                <v-flex>
                  <v-select
                    :items="paymentAmounts"
                    :label="$t('user.animals.amount')"
                    item-text="text"
                    item-value="value"
                    v-model="selectedAmount"
                    :disabled="loading"
                  ></v-select>
                </v-flex>
                <v-flex
                  xs12
                  v-if="selectedAmount === '-1'"
                >
                  <v-text-field
                    v-model="amount"
                    :rules="[rules.required, rules.minimumPayment]"
                    :disabled="loading"
                    :label="$t('user.animals.amount')"
                    :prefix="$t('user.animals.currency')"
                    type="number"
                    required
                  />
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    v-model="checkbox"
                    :rules="[rules.required]"
                    label="Adoptovaním miláčika na diaľku súhlasíte s uložením Vašich údajov do databáze priateľov
                Slobody zvierat a zasielaním pravidelného elektronického newslettru. Z odberu newslettru
                sa môžete odhlásiť kedykoľvek, pomocou odkazu na konci každého newslettru. V prípade
                otázok nás kontaktujte na utulok@slobodazvierat.sk."
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs12
                >
                  <v-radio-group v-model="paymentMethod">
                    <v-radio
                      :label="$t('user.animals.creditCard')"
                      :value="1"
                      :disabled="loading"
                    ></v-radio>
                    <v-radio
                      :label="$t('user.animals.paypal')"
                      :value="3"
                      :disabled="loading"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click.native="createAccount"
            :loading="loading"
            :disabled="!valid"
          >
            {{ $t('user.animals.subscribeButton') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import awsExports from '@/aws-exports.js';
import i18n from '@/i18n';
import { Auth } from 'aws-amplify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class AnimalSubscribe extends Vue {
  public $refs!: {
    form: any,
  };

  private step: number = 1;
  private loggedIn: boolean = false;
  private user: any = {};
  public dialog: boolean = false;
  private loading: boolean = false;
  private valid: boolean = true;
  private amount: string = '';
  private paymentMethod: number = 1;
  private firstName: string = '';
  private lastName: string = '';
  private paymentAmounts: any = [
    {
      text: '€5',
      value: '5',
    },
    {
      text: '€10',
      value: '10',
    },
    {
      text: '€20',
      value: '20',
    },
    {
      text: i18n.t('user.animals.customAmount'),
      value: '-1',
    },
  ];
  private selectedAmount: string = '5';

  private genders: any = [
    {
      text: i18n.t('user.animals.male'),
      value: 'male',
    },
    {
      text: i18n.t('user.animals.female'),
      value: 'female',
    },
  ];
  private gender: string = '';
  private checkbox: boolean = false;

  @Prop({type: Object, default: {}}) public animal!: any;
  @Getter('common/rules/all') private rules!: any;

  @Action('user/subscriptions/create') private createSubscription!: any;
  @Action('guest/auth/signUp') private signUp!: any;
  @Action('guest/auth/signIn') private signIn!: any;
  @Action('guest/auth/confirmSignUp') private confirmSignUp!: any;

  private async createAccount(e) {
    e.preventDefault();
    if (this.$refs.form.validate()) {
      this.loading = true;

      // Step 1: create Account
      const res = await this.signUp(this.user);

      // Step 2: if successful, log in and continue to forward user to payment
      if (res) {
        await this.signIn(this.user);
        await this.subscribe();
      }
      this.loading = false;
    }
  }

  private async subscribe() {
    if (this.$refs.form.validate()) {
      this.loading = true;

      if (this.selectedAmount !== '-1') {
        this.amount = this.selectedAmount;
      }
      // const val = Math.round(100 * parseFloat(this.amount));

      // const user = await Auth.currentUserInfo();
      const user = await Auth.currentAuthenticatedUser();

      await Auth.updateUserAttributes(user, {
        given_name: this.firstName,
        family_name: this.lastName,
        gender: this.gender,
      });

      let paymentEndpoint = '';
      if (awsExports && awsExports.aws_cloud_logic_custom instanceof Array) {
        const endpoints =
          awsExports.aws_cloud_logic_custom.filter((elem: any) => elem.name === 'slobodaPaymentApi');
        if (endpoints.length === 1) {
          paymentEndpoint = endpoints[0].endpoint;
        }
      }

      const data = {
        id_user: user.attributes.sub,
        id_animal: this.animal.id,
        client: 30,
        country: 2,
        language: 'sk',
        payment_type: this.paymentMethod,
        email: user.attributes.email,
        fname: this.firstName,
        lname: this.lastName,
        amount: parseFloat(this.amount),
        successUrl: `${window.location.origin}/#/user/payment/success`,
        errorUrl: `${window.location.origin}/#/user/payment/error`,
        paymentEndpoint,
      };

      await this.createSubscription(data);

      const base64 = btoa(unescape(encodeURIComponent(JSON.stringify(data))));

      window.location.href = `${process.env.VUE_APP_PAYMENT_URL}?data=${base64}`;

      this.loading = false;
    }
  }

  private async mounted() {
    await Auth.currentUserInfo();
  }
}
</script>

<style scoped>

</style>
