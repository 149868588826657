import * as API from '../_api';

import i18n from '@/i18n';

// @ts-ignore
export const read = async ({ commit, dispatch }) => {
  try {
    dispatch('common/loader/addNavLoader', null, { root: true });
    const all = await API.read();
    commit('setAll', all);
    dispatch('common/loader/removeNavLoader', null, { root: true });
    return true;
  } catch (ex) {
    dispatch('common/loader/removeNavLoader', null, { root: true });
    dispatch('common/snackbar/error', i18n.t('user.error'), { root: true });
    return false;
  }
};

// @ts-ignore
export const readOne = async ({ commit, dispatch, state }, { id }) => {
  try {
    dispatch('common/loader/addNavLoader', null, { root: true });
    const one = await API.readOne(id, 20, state.lastEvaluatedKey);
    commit('setOne', one);
    dispatch('common/loader/removeNavLoader', null, { root: true });
    return true;
  } catch (ex) {
    dispatch('common/loader/removeNavLoader', null, { root: true });
    dispatch('common/snackbar/error', i18n.t('user.error'), { root: true });
    return false;
  }
};
