<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12 sm8 offset-sm2 md8 offset-md2 lg8 offset-lg2 xl6 offset-xl3>
        <card
          color="primary"
          :title="$t('user.settings.settings')">
          <v-text-field
            v-model="preferred_usernameField"
            label="Používateľské meno"
            required
          ></v-text-field>
          <v-btn color="primary" @click="updateName" :loading="loading">Uložiť</v-btn>
        </card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm8 offset-sm2 md8 offset-md2 lg8 offset-lg2 xl6 offset-xl3>
        <card
          color="primary"
          :title="$t('user.settings.subscriptions')">
          <v-data-table
            :headers="tableHeaders"
            :items="subscriptions"
            hide-actions
            :loading="loading"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.animal ? props.item.animal.title : props.item.animalId }}</td>
              <td>
                <v-chip class="primary lighten-3" disabled>
                  <span class="white--text">{{ props.item.status | subscriptionStatus }}</span>
                </v-chip>
              </td>
              <td>
                <dlg-subscriptions-delete
                  :key="props.item.id"
                  :subscription="props.item"
                  v-if="props.item.status <= 50"
                ></dlg-subscriptions-delete>
              </td>
            </template>
          </v-data-table>
        </card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Card from '@/components/material/Card.vue';
import DlgSubscriptionsDelete from '@/modules/user/subscriptions/_components/DlgSubscriptionsDelete.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
             components: {DlgSubscriptionsDelete, Card},
           })
export default class Settings extends Vue {
  public loading: boolean = false;
  public tableHeaders: any = [
    {
      text: 'Zviera',
      align: 'left',
      value: 'title',
    },
    {
      text: 'Stav',
      align: 'left',
      value: 'status',
    },
    {
      text: '',
      align: 'left',
      sortable: false,
      value: '',
    },
  ];

  @Getter('user/subscriptions/all') private subscriptions!: any;
  @Getter('guest/auth/preferred_username') private preferred_username!: string;

  @Action('user/subscriptions/read') private readSubscriptions!: any;
  @Action('guest/auth/updatePreferred_Username') private updatePreferred_Username!: (preferred_username: string) => Promise<void>;

  public preferred_usernameField: string = '';

  private async created() {
    this.loading = true;
    await this.readSubscriptions();
    this.setUserName();
    this.loading = false;
  }

  @Watch('preferred_username')
  private setUserName() {
    console.log('preferred_username', this.preferred_username)
    this.preferred_usernameField = this.preferred_username;
  }

  private mounted() {
    this.setUserName();
  }

  public async updateName() {
    this.loading = true;
    await this.updatePreferred_Username(this.preferred_usernameField);
    this.loading = false;
  }

  // private stopSubscription(subscription: any) {
  // }
}
</script>

<style scoped>

</style>
