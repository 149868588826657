// @ts-ignore
export const start = (state, { message, color }) => {
  const newSnackbar = {
    color,
    message,
    active: true,
  };

  state.snackbars.push(newSnackbar);
};

