import { render, staticRenderFns } from "./PostCommentField.vue?vue&type=template&id=5b90e2ed&scoped=true"
import script from "./PostCommentField.vue?vue&type=script&lang=ts"
export * from "./PostCommentField.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b90e2ed",
  null
  
)

export default component.exports