import { API, Storage } from 'aws-amplify';

// @ts-ignore
export const create = async (post, commentText) => API.put(
  'slobodaAdminApi',
  `/comments/${post.id}`,
  { body: { commentText } },
);

// @ts-ignore
export const del = async (comment) => API.del('slobodaAdminApi', `/comments/${comment.id}`, {});
