<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg6 xl4>
        <v-form ref="form" @submit="Confirm" lazy-validation>
          <v-card class="elevation-12">
            <v-card-title primary-title class="primary">
              <div class="headline white--text">{{$t('auth.newPassword.header')}}</div>
            </v-card-title>
            <v-card-text>
              <p>
                {{$t('auth.newPassword.explanation')}}
              </p>
            </v-card-text>
            <v-card-text>
              <v-text-field
                autocomplete="email"
                prepend-icon="mail"
                name="email"
                :label="$t('auth.email')"
                :rules="[ rules.required, rules.email ]"
                :disabled="loading"
                v-model="user.username"
                type="text">
              </v-text-field>
              <v-text-field
                autocomplete="old-password"
                id="oldPassword"
                prepend-icon="lock"
                name="oldPassword"
                :rules="[ rules.required, rules.password ]"
                :label="$t('auth.oldPassword.title')"
                :disabled="loading"
                v-model="user.password"
                type="password">
              </v-text-field>
              <v-text-field
                autocomplete="new-password"
                id="newPassword"
                prepend-icon="lock"
                name="newPassword"
                :rules="[ rules.required, rules.password ]"
                :label="$t('auth.newPassword.title')"
                :disabled="loading"
                v-model="user.newPassword"
                type="password">
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="Confirm"
                :loading="loading"
                type="submit">
                {{$t('auth.newPassword.header')}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';

  @Component
  export default class SignUp extends Vue {
    public $refs!: {
      form: any,
    };

    private user: any = {};
    private loading: boolean = false;
    @Getter('common/rules/all') private rules!: any;
    @Getter('guest/auth/username') private username!: any;
    @Getter('guest/auth/password') private password!: any;
    @Getter('guest/auth/oldPassword') private oldPassword!: any;

    @Action('guest/auth/signIn') private signIn!: any;
    @Action('guest/auth/completeNewPassword') private completeNewPassword!: any;
    @Action('guest/auth/forgotPasswordSubmit') private forgotPasswordSubmit!: any;
    @Action('guest/auth/resendSignUp') private resendSignUp!: any;
    @Action('guest/auth/confirmSignUp') private confirmSignUp!: any;

    private mounted() {
      if (this.username) {
        this.user.username = this.username;
      }
      if (this.oldPassword) {
        this.user.oldPassword = this.oldPassword;
      }
    }

    private async Confirm(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.loading = true;
        const res = await this.completeNewPassword(this.user);
        if (res) {
          this.user.password = this.user.newPassword;
          if (await this.signIn(this.user)) {
            this.$router.push({name: 'UserBase'});
          } else {
            this.$router.push({name: 'SignIn'});
          }
        }
        this.loading = false;
      }
    }
  }
</script>

<style scoped>

</style>
