<template>
  <div>
    <v-container>
      <v-layout row wrap>
        <v-flex xs12 class="text-xs-center">
          <loader v-if="loading"></loader>
        </v-flex>
        <v-flex  >
          <v-layout row wrap>
            <v-flex
              v-if="animals"
              v-for="(animal) in animals"
              :key="animal.id"
              xs12 sm6 md4
              class="pa-2 d-flex"
              style="cursor: pointer"
            >
              <router-link :to="{ name: 'ModeratorAnimal', params: { id: animal.id }}" style="display: flex; flex-grow: 1"
                           class="no-link">
              <animal-card :animal="animal">

                <div class="px-3 pb-3" style="display: flex; justify-content: center; align-items: center">
                  <dlg-animals-delete :animal="animal" v-show="!animal.deleted" />
                  <span class="subheading mr-2"><v-icon class="mr-1 text-primary">group</v-icon>{{ animal.subscriptionCount }}</span>
                </div>
              </animal-card>
              </router-link>
            </v-flex>
            <v-flex xs12 v-if="loading" class="text-xs-center">
              <Loader></Loader>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>

    <dlg-animals-new/>
  </div>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import {
    Action,
    Getter,
  } from 'vuex-class';
  import DlgAnimalsNew from '@/modules/moderator/animals/_components/DlgAnimalsNew.vue';
  import DlgAnimalsEdit from '@/modules/moderator/animals/_components/DlgAnimalsEdit.vue';
  import S3Image from '@/components/S3Image.vue';
  import DlgAnimalsDelete from '@/modules/moderator/animals/_components/DlgAnimalsDelete.vue';
  import Loader from '@/components/Loader.vue';
  import AnimalCard from "@/components/Global/AnimalCard.vue";

  @Component({
    components: {
      AnimalCard,
      Loader,
      DlgAnimalsDelete,
      S3Image,
      DlgAnimalsEdit,
      DlgAnimalsNew,
    },
  })
  export default class AnimalsList extends Vue {
    public loading: boolean = false;
    @Action('moderator/animals/read') private readAnimals!: () => Promise<boolean>;
    @Getter('moderator/animals/all') public animals!: any[];

    private mounted() {
      this.loading = true;

      this.readAnimals()
        .then((success) => {
          if (success) {
            this.loading = false;
          } else {
            throw new Error('Some Error, handle it');
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    }

    private getAnimalText(description: string) {
      return description ? description.replace(/\n/gm, '</br>') : '';
    }

    private getUrl(i: number) {
      return `https://loremflickr.com/563/957/dog/all?dude=${i}`;
    }

    public goToAnimal(animalId: string) {
      this.$router.push({name: 'ModeratorAnimal', params: {id: animalId}});
    }
  }
</script>

<style scoped>
  .deleted {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .radius {
    border-radius: 10px;
  }

  .center {
    text-align: center;
  }

  .link:hover {
    cursor: pointer;
  }
</style>
