<template>
  <div>
    <v-container>
      <card
        color="primary"
        title="Subscriptions"
        v-model="search"
      >
        <v-data-table
          :headers="headers"
          :items="subscriptions"
          :loading="loading"
          :search="search"
          :rows-per-page-items="[15, 10, 5]"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.var_sym }}</td>
            <td>
              <router-link :to="{ name: 'AdminAnimal', params: { id: props.item.animalId }}" class="link">
                {{ props.item.animal | animal }}
              </router-link>
            </td>
            <td>
              <router-link :to="{ name: 'AdminUser', params: { id: props.item.userSub }}" class="link">
                {{ props.item.user | user }}
              </router-link>
            </td>
            <td>{{ props.item.amount | money }}</td>
            <td>{{ props.item.createdAt | timestamp }}</td>
            <td>{{ props.item.entry_date | timestamp }}</td>
            <td>{{ props.item.daysSince | days }}</td>
          </template>
        </v-data-table>
      </card>
    </v-container>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import S3Image from '@/components/S3Image.vue';
import Card from '@/components/material/Card.vue';

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

@Component({
  components: {
    Card,
    S3Image,
  },
  filters: {
    animal(val) {
      return val;
    },
    user(val) {
      return val;
    },
    money(val) {
      if (val) {
        return formatter.format(val);
      }
    },
    timestamp(val) {
      const date = new Date(val);
      return date.toLocaleDateString('de-DE');
    },
    days(val) {
      return `${val} Days`;
    },
  },
})
export default class UsersList extends Vue {
  private loading: boolean = false;

  @Action('admin/subscriptions/read') private readSubscriptions!: () => Promise<boolean>;
  @Action('admin/animals/read') private readAnimals!: () => Promise<boolean>;
  @Action('admin/users/read') private readUsers!: () => Promise<boolean>;
  @Getter('admin/subscriptions/all') private subscriptions!: any[];
  @Getter('admin/animals/all') private animals!: any[];
  @Getter('admin/users/all') private users!: any[];

  private headers: any = [
    {
      text: 'VarSym',
      value: 'var_sym',
    },
    {
      text: 'Animal',
      value: 'animal',
    },
    {
      text: 'User',
      value: 'user',
    },
    {
      text: 'Amount',
      value: 'amount',
    },
    {
      text: 'Created',
      value: 'createdAt',
    },
    {
      text: 'Last Payment',
      value: 'entry_date',
    },
    {
      text: 'Days active',
      align: 'left',
      value: 'daysSince',
    },
  ];

  private search: string = '';

  private async mounted() {
    this.loading = true;
    try {
      await Promise.all([
        this.readUsers(),
        this.readAnimals(),
        this.readSubscriptions(),
      ]);
      this.loading = false;
    } catch (ex) {
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.link{
  font-family: Arial, sans-serif;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}
</style>
